<template>
  <svg width="52" height="20" viewBox="0 0 52 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 4C0.5 1.79086 2.29086 0 4.5 0H47.5C49.7091 0 51.5 1.79086 51.5 4V16C51.5 18.2091 49.7091 20 47.5 20H4.5C2.29086 20 0.5 18.2091 0.5 16V4Z"
      fill="#5E5E5E" />
    <g clip-path="url(#clip0_16423_5162)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.331 5.66897C15.9391 3.27701 12.0609 3.27701 9.66897 5.66897C7.27701 8.06093 7.27701 11.9391 9.66897 14.331C12.0609 16.723 15.9391 16.723 18.331 14.331C20.723 11.9391 20.723 8.06093 18.331 5.66897ZM16.9115 7.41919C17.0994 7.57102 17.1287 7.84647 16.9769 8.03444L13.5258 12.3071C13.4502 12.4008 13.3392 12.4591 13.2191 12.4684C13.099 12.4776 12.9804 12.4369 12.8913 12.3559L11.0569 10.689C10.878 10.5265 10.8648 10.2498 11.0273 10.071C11.1898 9.89218 11.4665 9.87895 11.6453 10.0414L13.1365 11.3965L16.2962 7.48463C16.4481 7.29667 16.7235 7.26737 16.9115 7.41919Z"
        fill="#F9F9F9" />
    </g>
    <rect x="23" y="8" width="20" height="4" rx="2" fill="#F9F9F9" />
    <defs>
      <clipPath id="clip0_16423_5162">
        <rect width="14" height="14" fill="white" transform="translate(7 3)" />
      </clipPath>
    </defs>
  </svg>
</template>
