<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.3772 2.82032C10.3772 2.36727 10.007 2 9.5502 2C9.09345 2 8.72318 2.36727 8.72318 2.82032V4.50385C8.72318 4.95689 9.09345 5.32416 9.5502 5.32416C10.007 5.32416 10.3772 4.95689 10.3772 4.50385V2.82032ZM5.51565 4.3466C5.1941 4.02484 4.67046 4.02255 4.34607 4.34149C4.02168 4.66043 4.01937 5.17982 4.34092 5.50158L5.60168 6.76317C5.92323 7.08493 6.44687 7.08721 6.77126 6.76828C7.09565 6.44934 7.09796 5.92994 6.77641 5.60818L5.51565 4.3466ZM14.7603 5.50158C15.0819 5.17982 15.0796 4.66043 14.7552 4.34149C14.4308 4.02255 13.9072 4.02484 13.5856 4.3466L12.3249 5.60818C12.0033 5.92994 12.0056 6.44934 12.33 6.76828C12.6544 7.08721 13.178 7.08493 13.4996 6.76317L14.7603 5.50158ZM2.82703 8.73157C2.37027 8.73157 2 9.09884 2 9.55189C2 10.0049 2.37027 10.3722 2.82703 10.3722H4.50718C4.96393 10.3722 5.3342 10.0049 5.3342 9.55189C5.3342 9.09884 4.96393 8.73157 4.50718 8.73157H2.82703ZM6.77575 13.4945C7.09719 13.1727 7.0947 12.6533 6.7702 12.3345C6.4457 12.0156 5.92206 12.0181 5.60062 12.34L4.33987 13.6024C4.01843 13.9243 4.02091 14.4436 4.34541 14.7625C4.66991 15.0813 5.19355 15.0788 5.51499 14.757L6.77575 13.4945ZM9.92881 8.95364C9.65025 8.84222 9.33209 8.90752 9.11994 9.11967C8.90779 9.33182 8.84249 9.64998 8.95391 9.92854L12.3139 18.3285C12.428 18.6138 12.7045 18.8006 13.0118 18.8C13.319 18.7994 13.5948 18.6115 13.7077 18.3257L15.0163 15.016L18.326 13.7075C18.6117 13.5945 18.7996 13.3187 18.8003 13.0115C18.8009 12.7043 18.6141 12.4277 18.3288 12.3136L9.92881 8.95364ZM13.0062 16.0204L10.9966 10.9963L16.0206 13.0059L14.1625 13.7405C13.9697 13.8168 13.817 13.9694 13.7408 14.1623L13.0062 16.0204Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'TriggerActionClick'
};
</script>
