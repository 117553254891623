<template>
  <svg width="100%" height="100%" viewBox="0 0 94 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <rect width="22" height="10" rx="3" fill="#AAAAAA" />
      <rect y="14" width="22" height="10" rx="3" fill="#AAAAAA" />
      <rect x="26" width="68" height="40" rx="3" fill="#5E5E5E" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TabTop',
};
</script>
