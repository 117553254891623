<template>
  <svg width="51" height="24" viewBox="0 0 51 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_16646_8758)">
      <circle cx="5.5" cy="5" r="4.5" stroke="#F9F9F9" />
      <circle cx="5.5" cy="5" r="3" fill="#F9F9F9" />
      <rect x="0.5" y="12" width="50" height="3.33333" rx="1.66667" fill="#5E5E5E" />
      <rect x="0.5" y="16.3333" width="50" height="3.33333" rx="1.66667" fill="#5E5E5E" />
      <rect x="0.5" y="20.6667" width="21" height="3.33333" rx="1.66667" fill="#5E5E5E" />
    </g>
    <defs>
      <clipPath id="clip0_16646_8758">
        <rect width="50" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>
