<template>
  <svg width="100%" height="100%" viewBox="0 0 94 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 3C0 1.34315 1.34315 0 3 0H19C20.6569 0 22 1.34315 22 3V27C22 28.6569 20.6569 30 19 30H3C1.34315 30 0 28.6569 0 27V3Z" fill="#5E5E5E"/>
<path d="M3.5 9.26601L8.49502 7.52875C8.49502 7.52875 9.10236 8.94539 11.005 8.94539C12.9076 8.94539 13.5083 7.5 13.5083 7.5L18.5 9.26601L17.6692 13.6222H15.1642V21.5H6.82503V13.6196H4.3325L3.5 9.26601Z" fill="#D6D6D6"/>
<path d="M24 3C24 1.34315 25.3431 0 27 0H43C44.6569 0 46 1.34315 46 3V27C46 28.6569 44.6569 30 43 30H27C25.3431 30 24 28.6569 24 27V3Z" fill="#5E5E5E"/>
<path d="M27.5 9.26601L32.495 7.52875C32.495 7.52875 33.1024 8.94539 35.005 8.94539C36.9076 8.94539 37.5083 7.5 37.5083 7.5L42.5 9.26601L41.6692 13.6222H39.1642V21.5H30.825V13.6196H28.3325L27.5 9.26601Z" fill="#D6D6D6"/>
<path d="M48 3C48 1.34315 49.3431 0 51 0H67C68.6569 0 70 1.34315 70 3V27C70 28.6569 68.6569 30 67 30H51C49.3431 30 48 28.6569 48 27V3Z" fill="#5E5E5E"/>
<path d="M51.5 9.26601L56.495 7.52875C56.495 7.52875 57.1024 8.94539 59.005 8.94539C60.9076 8.94539 61.5083 7.5 61.5083 7.5L66.5 9.26601L65.6692 13.6222H63.1642V21.5H54.825V13.6196H52.3325L51.5 9.26601Z" fill="#D6D6D6"/>
<path d="M72 3C72 1.34315 73.3431 0 75 0H91C92.6569 0 94 1.34315 94 3V27C94 28.6569 92.6569 30 91 30H75C73.3431 30 72 28.6569 72 27V3Z" fill="#5E5E5E"/>
<path d="M75.5 9.26601L80.495 7.52875C80.495 7.52875 81.1024 8.94539 83.005 8.94539C84.9076 8.94539 85.5083 7.5 85.5083 7.5L90.5 9.26601L89.6692 13.6222H87.1642V21.5H78.825V13.6196H76.3325L75.5 9.26601Z" fill="#D6D6D6"/>
</svg>
</template>

<script>
export default {
  name: 'GridLayout'
};
</script>
