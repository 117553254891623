import type { RadioProps } from '../types';

import { computed } from 'vue';
import { useRadioTone } from './useRadioTone';

export function useRadioSelection({ props }: { props: RadioProps }) {
  const { isLightTone, isDarkTone } = useRadioTone({ props });

  const isSelected = computed(() => props.modelValue === props.option.value);

  const radioActiveCls = computed(() => {
    if (!isSelected.value) return [];

    if (isLightTone.value) {
      return ['border-primary-300', 'before:bg-primary-300', 'before:h-10', 'before:w-10', 'before:rounded-full'];
    }

    return [];
  });

  const radioInactiveCls = computed(() => {
    if (isSelected.value) return [];

    if (isLightTone.value) {
      return ['border-text-dark-300'];
    }
    return [];
  });

  const radioHoverCls = computed(() => {
    if (isDarkTone.value) return ['group-hover/radio:bg-overlay-white'];
    if (isLightTone.value) return ['group-hover/radio:bg-overlay-grey'];

    return [];
  });

  return { isSelected, radioActiveCls, radioInactiveCls, radioHoverCls };
}
