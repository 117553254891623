<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.45945 3.89926C6.27199 4.09408 5.96806 4.09407 5.78062 3.89923L4.80005 2.88002L4.80005 5.29146C4.80005 5.62283 4.53142 5.89146 4.20005 5.89146C3.86868 5.89146 3.60005 5.62283 3.60005 5.29146L3.60005 2.88012L2.61946 3.89925C2.43201 4.09408 2.12808 4.09407 1.94063 3.89924C1.75318 3.70441 1.75319 3.38853 1.94064 3.1937L3.62365 1.44454C3.93838 1.11743 4.46192 1.11745 4.77664 1.44457L6.45947 3.19372C6.64692 3.38855 6.64691 3.70443 6.45945 3.89926Z"
      fill="#F9F9F9" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.35571 5.42428C7.52542 5.25457 7.77995 5.20232 8.0028 5.29146L11.2028 6.57146L14.4028 7.85146C14.631 7.94274 14.7805 8.16397 14.78 8.40975C14.7795 8.65553 14.6291 8.87616 14.4006 8.96652L11.8904 9.95893L10.8979 12.4691C10.8076 12.6977 10.587 12.8481 10.3412 12.8485C10.0954 12.849 9.87417 12.6996 9.78288 12.4714L7.22288 6.07138C7.13374 5.84853 7.18599 5.594 7.35571 5.42428ZM8.857 6.92558L10.3367 10.6248L10.87 9.27595C10.931 9.12169 11.0531 8.99956 11.2074 8.93857L12.5563 8.40529L8.857 6.92558Z"
      fill="#F9F9F9" />
    <path
      d="M4.80005 10.4914C4.80005 10.16 4.53142 9.89141 4.20005 9.89141C3.86868 9.89141 3.60005 10.16 3.60005 10.4914L3.60005 12.9028L2.61948 11.8836C2.43203 11.6888 2.12811 11.6888 1.94065 11.8836C1.75319 12.0784 1.75318 12.3943 1.94063 12.5891L3.62346 14.3383C3.93818 14.6654 4.46172 14.6654 4.77645 14.3383L6.45945 12.5892C6.64691 12.3943 6.64692 12.0785 6.45946 11.8836C6.27201 11.6888 5.96809 11.6888 5.78063 11.8836L4.80005 12.9027L4.80005 10.4914Z"
      fill="#F9F9F9" />
  </svg>
</template>

<script>
export default {
  name: 'ParallaxEffect',
};
</script>
