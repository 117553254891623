<template>
  <svg width="100%" height="100%" viewBox="0 0 64 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 16C0.5 7.43959 7.43959 0.5 16 0.5H48C56.5604 0.5 63.5 7.43959 63.5 16C63.5 24.5604 56.5604 31.5 48 31.5H16C7.43959 31.5 0.5 24.5604 0.5 16Z" stroke="#5E5E5E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.80005 15.9999C9.80005 15.6685 10.0687 15.3999 10.4 15.3999H15.6C15.9314 15.3999 16.2 15.6685 16.2 15.9999C16.2 16.3313 15.9314 16.5999 15.6 16.5999H10.4C10.0687 16.5999 9.80005 16.3313 9.80005 15.9999Z" fill="#F9F9F9"/>
<path d="M33.2051 12V20H31.9941V13.2109H31.9473L30.0332 14.4609V13.3047L32.0293 12H33.2051Z" fill="white"/>
<path d="M51.6 13.4C51.6 13.0687 51.3314 12.8 51 12.8C50.6687 12.8 50.4 13.0687 50.4 13.4L50.4 15.4H48.4C48.0687 15.4 47.8 15.6687 47.8 16C47.8 16.3314 48.0687 16.6 48.4 16.6H50.4L50.4 18.6C50.4 18.9314 50.6687 19.2 51 19.2C51.3314 19.2 51.6 18.9314 51.6 18.6V16.6H53.6C53.9314 16.6 54.2 16.3314 54.2 16C54.2 15.6687 53.9314 15.4 53.6 15.4H51.6V13.4Z" fill="#F9F9F9"/>
</svg>
</template>

<script>
export default {
  name: 'ProductQuantityRounded'
};
</script>
