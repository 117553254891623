<template>
  <svg width="100%" height="100%" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" rx="3" fill="#5E5E5E"/>
<path d="M6 7.81686L9.99601 6.42705C9.99601 6.42705 10.4819 7.56037 12.004 7.56037C13.5261 7.56037 14.0066 6.40405 14.0066 6.40405L18 7.81686L17.3353 11.3018H15.3313L15.3313 17.604H8.66002L8.66002 11.2997H6.666L6 7.81686Z" fill="#F9F9F9"/>
<rect x="26" width="24" height="24" rx="3" fill="#5E5E5E"/>
<path d="M32 7.81686L35.996 6.42705C35.996 6.42705 36.4819 7.56037 38.004 7.56037C39.5261 7.56037 40.0066 6.40405 40.0066 6.40405L44 7.81686L43.3353 11.3018H41.3313V17.604H34.66V11.2997H32.666L32 7.81686Z" fill="#F9F9F9"/>
<rect y="26" width="24" height="24" rx="3" fill="#5E5E5E"/>
<path d="M6 33.8169L9.99601 32.4271C9.99601 32.4271 10.4819 33.5604 12.004 33.5604C13.5261 33.5604 14.0066 32.4041 14.0066 32.4041L18 33.8169L17.3353 37.3018H15.3313L15.3313 43.604H8.66002L8.66002 37.2997H6.666L6 33.8169Z" fill="#F9F9F9"/>
<rect x="26" y="26" width="24" height="24" rx="3" fill="#5E5E5E"/>
<path d="M32 33.8169L35.996 32.4271C35.996 32.4271 36.4819 33.5604 38.004 33.5604C39.5261 33.5604 40.0066 32.4041 40.0066 32.4041L44 33.8169L43.3353 37.3018H41.3313V43.604H34.66V37.2997H32.666L32 33.8169Z" fill="#F9F9F9"/>
</svg>
</template>

<script>
export default {
  name: 'ProductImageLayoutTwoCol'
};
</script>
