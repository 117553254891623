<template>
  <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.19995 6.6498C3.19995 5.07579 4.47594 3.7998 6.04995 3.7998H12.05C13.624 3.7998 14.9 5.07579 14.9 6.64981V7.2498C14.9 7.66402 15.2357 7.9998 15.65 7.9998C16.0642 7.9998 16.4 7.66402 16.4 7.2498V6.64981C16.4 4.24737 14.4524 2.2998 12.05 2.2998H6.04995C3.64751 2.2998 1.69995 4.24736 1.69995 6.6498V12.6498C1.69995 15.0522 3.64751 16.9998 6.04995 16.9998H7.84995C8.26416 16.9998 8.59995 16.664 8.59995 16.2498C8.59995 15.8356 8.26416 15.4998 7.84995 15.4998H6.04995C4.47594 15.4998 3.19995 14.2238 3.19995 12.6498V6.6498ZM9.92849 8.35345C9.64993 8.24202 9.33177 8.30733 9.11962 8.51947C8.90747 8.73162 8.84217 9.04979 8.95359 9.32835L12.3136 17.7283C12.4277 18.0136 12.7042 18.2004 13.0115 18.1998C13.3187 18.1992 13.5945 18.0113 13.7074 17.7255L15.0159 14.4158L18.3257 13.1073C18.6114 12.9943 18.7993 12.7185 18.7999 12.4113C18.8006 12.1041 18.6137 11.8275 18.3285 11.7134L9.92849 8.35345ZM13.0059 15.4202L10.9962 10.3961L16.0203 12.4057L14.1622 13.1403C13.9694 13.2166 13.8167 13.3692 13.7405 13.5621L13.0059 15.4202Z"
      fill="#F9F9F9" />
  </svg>
</template>

<script>
export default {
  name: 'CheckHover',
};
</script>
