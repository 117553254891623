<template>
  <svg width="100%" height="100%" viewBox="0 0 64 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_12019_110929)">
    <path d="M0 2.25C0 1.00736 1.00736 0 2.25 0H61.75C62.9926 0 64 1.00736 64 2.25V37.75C64 38.9926 62.9926 40 61.75 40H2.25C1.00736 40 0 38.9926 0 37.75V2.25Z" fill="#333333"/>
    <path d="M6 6.5C6 5.25736 7.00736 4.25 8.25 4.25H21.7501C22.9927 4.25 24.0001 5.25736 24.0001 6.5V20.0001C24.0001 21.2427 22.9927 22.2501 21.7501 22.2501H8.25C7.00736 22.2501 6 21.2427 6 20.0001V6.5Z" fill="#424242"/>
    <path d="M10.3125 9.97876L13.4344 8.89297C13.4344 8.89297 13.814 9.77837 15.0031 9.77837C16.1923 9.77837 16.5677 8.875 16.5677 8.875L19.6875 9.97876L19.1683 12.7014H17.6026V17.625H12.3907V12.6998H10.8328L10.3125 9.97876Z" fill="#757575"/>
    <path d="M27 6.5C27 5.25736 28.0074 4.25 29.25 4.25H55.7499C56.9926 4.25 57.9999 5.25736 57.9999 6.5C57.9999 7.74264 56.9926 8.75 55.7499 8.75H29.25C28.0074 8.75 27 7.74264 27 6.5Z" fill="#424242"/>
    <path d="M27 14C27 12.7574 28.0074 11.75 29.25 11.75H35.25C36.4926 11.75 37.5 12.7574 37.5 14C37.5 15.2426 36.4926 16.25 35.25 16.25H29.25C28.0074 16.25 27 15.2426 27 14Z" fill="#424242"/>
    <path d="M39 14C39 12.7574 40.0074 11.75 41.25 11.75H47.25C48.4926 11.75 49.5 12.7574 49.5 14C49.5 15.2426 48.4926 16.25 47.25 16.25H41.25C40.0074 16.25 39 15.2426 39 14Z" fill="#424242"/>
    <rect width="64" height="13.5" transform="translate(0 26.5)" fill="#5E5E5E"/>
    <path d="M6 33.25C6 32.0074 7.00736 31 8.25 31H16.75C17.9926 31 19 32.0074 19 33.25C19 34.4926 17.9926 35.5 16.75 35.5H8.25C7.00736 35.5 6 34.4926 6 33.25Z" fill="#757575"/>
    <path d="M22 33.25C22 32.0074 23.0074 31 24.25 31H28.75C29.9926 31 31 32.0074 31 33.25C31 34.4926 29.9926 35.5 28.75 35.5H24.25C23.0074 35.5 22 34.4926 22 33.25Z" fill="#AAAAAA"/>
    <path d="M32.5 33.25C32.5 32.0074 33.5074 31 34.75 31H39.25C40.4926 31 41.5 32.0074 41.5 33.25C41.5 34.4926 40.4926 35.5 39.25 35.5H34.75C33.5074 35.5 32.5 34.4926 32.5 33.25Z" fill="#AAAAAA"/>
    <path d="M44.5 33.25C44.5 32.0074 45.5074 31 46.75 31H55.75C56.9926 31 58 32.0074 58 33.25C58 34.4926 56.9926 35.5 55.75 35.5H46.75C45.5074 35.5 44.5 34.4926 44.5 33.25Z" fill="#757575"/>
  </g>
  <defs>
    <clipPath id="clip0_12019_110929">
      <path d="M0 2.25C0 1.00736 1.00736 0 2.25 0H61.75C62.9926 0 64 1.00736 64 2.25V37.75C64 38.9926 62.9926 40 61.75 40H2.25C1.00736 40 0 38.9926 0 37.75V2.25Z" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>

<script>
export default {
  name: 'SyncProductOff'
};
</script>
