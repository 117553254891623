import polarisAdjustIcon from './polaris-icons/Adjust.vue';
import polarisAffiliateIcon from './polaris-icons/Affiliate.vue';
import polarisAirplaneIcon from './polaris-icons/Airplane.vue';
import polarisAlertBubbleIcon from './polaris-icons/AlertBubble.vue';
import polarisAlertCircleIcon from './polaris-icons/AlertCircle.vue';
import polarisAlertDiamondIcon from './polaris-icons/AlertDiamond.vue';
import polarisAlertTriangleIcon from './polaris-icons/AlertTriangle.vue';
import polarisAppExtensionIcon from './polaris-icons/AppExtension.vue';
import polarisAppsIcon from './polaris-icons/Apps.vue';
import polarisAppsFilledIcon from './polaris-icons/AppsFilled.vue';
import polarisArchiveIcon from './polaris-icons/Archive.vue';
import polarisArrowDiagonalIcon from './polaris-icons/ArrowDiagonal.vue';
import polarisArrowDownIcon from './polaris-icons/ArrowDown.vue';
import polarisArrowLeftIcon from './polaris-icons/ArrowLeft.vue';
import polarisArrowRightIcon from './polaris-icons/ArrowRight.vue';
import polarisArrowUpIcon from './polaris-icons/ArrowUp.vue';
import polarisArrowsInHorizontalIcon from './polaris-icons/ArrowsInHorizontal.vue';
import polarisArrowsOutHorizontalIcon from './polaris-icons/ArrowsOutHorizontal.vue';
import polarisArrowsOutHorizontalFilledIcon from './polaris-icons/ArrowsOutHorizontalFilled.vue';
import polarisAttachmentIcon from './polaris-icons/Attachment.vue';
import polarisAttachmentFilledIcon from './polaris-icons/AttachmentFilled.vue';
import polarisAutomationIcon from './polaris-icons/Automation.vue';
import polarisAutomationFilledIcon from './polaris-icons/AutomationFilled.vue';
import polarisBackspaceIcon from './polaris-icons/Backspace.vue';
import polarisBankIcon from './polaris-icons/Bank.vue';
import polarisBankFilledIcon from './polaris-icons/BankFilled.vue';
import polarisBarcodeIcon from './polaris-icons/Barcode.vue';
import polarisBillIcon from './polaris-icons/Bill.vue';
import polarisBillFilledIcon from './polaris-icons/BillFilled.vue';
import polarisBlankIcon from './polaris-icons/Blank.vue';
import polarisBlankFilledIcon from './polaris-icons/BlankFilled.vue';
import polarisBlogIcon from './polaris-icons/Blog.vue';
import polarisBookIcon from './polaris-icons/Book.vue';
import polarisBookOpenIcon from './polaris-icons/BookOpen.vue';
import polarisBugIcon from './polaris-icons/Bug.vue';
import polarisBulletIcon from './polaris-icons/Bullet.vue';
import polarisButtonIcon from './polaris-icons/Button.vue';
import polarisButtonPressIcon from './polaris-icons/ButtonPress.vue';
import polarisCalculatorIcon from './polaris-icons/Calculator.vue';
import polarisCalendarIcon from './polaris-icons/Calendar.vue';
import polarisCalendarCheckIcon from './polaris-icons/CalendarCheck.vue';
import polarisCalendarTimeIcon from './polaris-icons/CalendarTime.vue';
import polarisCameraIcon from './polaris-icons/Camera.vue';
import polarisCameraFlipIcon from './polaris-icons/CameraFlip.vue';
import polarisCaretDownIcon from './polaris-icons/CaretDown.vue';
import polarisCaretUpIcon from './polaris-icons/CaretUp.vue';
import polarisCartIcon from './polaris-icons/Cart.vue';
import polarisCartAbandonedIcon from './polaris-icons/CartAbandoned.vue';
import polarisCartAbandonedFilledIcon from './polaris-icons/CartAbandonedFilled.vue';
import polarisCartDiscountIcon from './polaris-icons/CartDiscount.vue';
import polarisCartDownIcon from './polaris-icons/CartDown.vue';
import polarisCartDownFilledIcon from './polaris-icons/CartDownFilled.vue';
import polarisCartFilledIcon from './polaris-icons/CartFilled.vue';
import polarisCartSaleIcon from './polaris-icons/CartSale.vue';
import polarisCartUpIcon from './polaris-icons/CartUp.vue';
import polarisCashDollarIcon from './polaris-icons/CashDollar.vue';
import polarisCashDollarFilledIcon from './polaris-icons/CashDollarFilled.vue';
import polarisCashEuroIcon from './polaris-icons/CashEuro.vue';
import polarisCashPoundIcon from './polaris-icons/CashPound.vue';
import polarisCashRupeeIcon from './polaris-icons/CashRupee.vue';
import polarisCashYenIcon from './polaris-icons/CashYen.vue';
import polarisCatalogIcon from './polaris-icons/Catalog.vue';
import polarisCategoriesIcon from './polaris-icons/Categories.vue';
import polarisChannelsIcon from './polaris-icons/Channels.vue';
import polarisChartCohortIcon from './polaris-icons/ChartCohort.vue';
import polarisChartDonutIcon from './polaris-icons/ChartDonut.vue';
import polarisChartFunnelIcon from './polaris-icons/ChartFunnel.vue';
import polarisChartHistogramFirstIcon from './polaris-icons/ChartHistogramFirst.vue';
import polarisChartHistogramFirstLastIcon from './polaris-icons/ChartHistogramFirstLast.vue';
import polarisChartHistogramFlatIcon from './polaris-icons/ChartHistogramFlat.vue';
import polarisChartHistogramFullIcon from './polaris-icons/ChartHistogramFull.vue';
import polarisChartHistogramGrowthIcon from './polaris-icons/ChartHistogramGrowth.vue';
import polarisChartHistogramLastIcon from './polaris-icons/ChartHistogramLast.vue';
import polarisChartHistogramSecondLastIcon from './polaris-icons/ChartHistogramSecondLast.vue';
import polarisChartHorizontalIcon from './polaris-icons/ChartHorizontal.vue';
import polarisChartLineIcon from './polaris-icons/ChartLine.vue';
import polarisChartPopularIcon from './polaris-icons/ChartPopular.vue';
import polarisChartStackedIcon from './polaris-icons/ChartStacked.vue';
import polarisChartVerticalIcon from './polaris-icons/ChartVertical.vue';
import polarisChartVerticalFilledIcon from './polaris-icons/ChartVerticalFilled.vue';
import polarisChatIcon from './polaris-icons/Chat.vue';
import polarisChatReferralIcon from './polaris-icons/ChatReferral.vue';
import polarisCheckIcon from './polaris-icons/Check.vue';
import polarisCheckCircleIcon from './polaris-icons/CheckCircle.vue';
import polarisCheckClickedIcon from './polaris-icons/CheckClicked.vue';
import polarisCheckHoverIcon from './polaris-icons/CheckHover.vue';
import polarisCheckSmallIcon from './polaris-icons/CheckSmall.vue';
import polarisCheckboxIcon from './polaris-icons/Checkbox.vue';
import polarisChevronDownIcon from './polaris-icons/ChevronDown.vue';
import polarisChevronLeftIcon from './polaris-icons/ChevronLeft.vue';
import polarisChevronRightIcon from './polaris-icons/ChevronRight.vue';
import polarisChevronUpIcon from './polaris-icons/ChevronUp.vue';
import polarisCircleChevronDownIcon from './polaris-icons/CircleChevronDown.vue';
import polarisCircleChevronLeftIcon from './polaris-icons/CircleChevronLeft.vue';
import polarisCircleChevronRightIcon from './polaris-icons/CircleChevronRight.vue';
import polarisCircleChevronUpIcon from './polaris-icons/CircleChevronUp.vue';
import polarisCircleDownIcon from './polaris-icons/CircleDown.vue';
import polarisCircleLeftIcon from './polaris-icons/CircleLeft.vue';
import polarisCircleRightIcon from './polaris-icons/CircleRight.vue';
import polarisCircleUpIcon from './polaris-icons/CircleUp.vue';
import polarisClipboardIcon from './polaris-icons/Clipboard.vue';
import polarisClipboardCheckIcon from './polaris-icons/ClipboardCheck.vue';
import polarisClipboardCheckFilledIcon from './polaris-icons/ClipboardCheckFilled.vue';
import polarisClipboardChecklistIcon from './polaris-icons/ClipboardChecklist.vue';
import polarisClockIcon from './polaris-icons/Clock.vue';
import polarisCodeIcon from './polaris-icons/Code.vue';
import polarisCodeAddIcon from './polaris-icons/CodeAdd.vue';
import polarisCollectionIcon from './polaris-icons/Collection.vue';
import polarisCollectionFeaturedIcon from './polaris-icons/CollectionFeatured.vue';
import polarisCollectionFilledIcon from './polaris-icons/CollectionFilled.vue';
import polarisCollectionListIcon from './polaris-icons/CollectionList.vue';
import polarisCollectionReferenceIcon from './polaris-icons/CollectionReference.vue';
import polarisColorIcon from './polaris-icons/Color.vue';
import polarisColorNoneIcon from './polaris-icons/ColorNone.vue';
import polarisCompassIcon from './polaris-icons/Compass.vue';
import polarisComposeIcon from './polaris-icons/Compose.vue';
import polarisConfettiIcon from './polaris-icons/Confetti.vue';
import polarisConnectIcon from './polaris-icons/Connect.vue';
import polarisContentIcon from './polaris-icons/Content.vue';
import polarisContentFilledIcon from './polaris-icons/ContentFilled.vue';
import polarisContractIcon from './polaris-icons/Contract.vue';
import polarisContractFilledIcon from './polaris-icons/ContractFilled.vue';
import polarisCornerPillIcon from './polaris-icons/CornerPill.vue';
import polarisCornerRoundIcon from './polaris-icons/CornerRound.vue';
import polarisCornerSquareIcon from './polaris-icons/CornerSquare.vue';
import polarisCreditCardIcon from './polaris-icons/CreditCard.vue';
import polarisCreditCardCancelIcon from './polaris-icons/CreditCardCancel.vue';
import polarisCreditCardPercentIcon from './polaris-icons/CreditCardPercent.vue';
import polarisCreditCardReaderIcon from './polaris-icons/CreditCardReader.vue';
import polarisCreditCardReaderChipIcon from './polaris-icons/CreditCardReaderChip.vue';
import polarisCreditCardReaderTapIcon from './polaris-icons/CreditCardReaderTap.vue';
import polarisCreditCardSecureIcon from './polaris-icons/CreditCardSecure.vue';
import polarisCreditCardTapChipIcon from './polaris-icons/CreditCardTapChip.vue';
import polarisCropIcon from './polaris-icons/Crop.vue';
import polarisCurrencyConvertIcon from './polaris-icons/CurrencyConvert.vue';
import polarisCursorIcon from './polaris-icons/Cursor.vue';
import polarisCursorBannerIcon from './polaris-icons/CursorBanner.vue';
import polarisCursorFilledIcon from './polaris-icons/CursorFilled.vue';
import polarisCursorOptionIcon from './polaris-icons/CursorOption.vue';
import polarisDataPresentationIcon from './polaris-icons/DataPresentation.vue';
import polarisDataTableIcon from './polaris-icons/DataTable.vue';
import polarisDatabaseIcon from './polaris-icons/Database.vue';
import polarisDatabaseAddIcon from './polaris-icons/DatabaseAdd.vue';
import polarisDatabaseConnectIcon from './polaris-icons/DatabaseConnect.vue';
import polarisDeleteIcon from './polaris-icons/Delete.vue';
import polarisDeliveryIcon from './polaris-icons/Delivery.vue';
import polarisDeliveryFilledIcon from './polaris-icons/DeliveryFilled.vue';
import polarisDesktopIcon from './polaris-icons/Desktop.vue';
import polarisDisabledIcon from './polaris-icons/Disabled.vue';
import polarisDiscountIcon from './polaris-icons/Discount.vue';
import polarisDiscountCodeIcon from './polaris-icons/DiscountCode.vue';
import polarisDiscountFilledIcon from './polaris-icons/DiscountFilled.vue';
import polarisDnsSettingsIcon from './polaris-icons/DnsSettings.vue';
import polarisDockFloatingIcon from './polaris-icons/DockFloating.vue';
import polarisDockSideIcon from './polaris-icons/DockSide.vue';
import polarisDomainIcon from './polaris-icons/Domain.vue';
import polarisDomainFilledIcon from './polaris-icons/DomainFilled.vue';
import polarisDomainLandingPageIcon from './polaris-icons/DomainLandingPage.vue';
import polarisDomainNewIcon from './polaris-icons/DomainNew.vue';
import polarisDomainRedirectIcon from './polaris-icons/DomainRedirect.vue';
import polarisDragDropIcon from './polaris-icons/DragDrop.vue';
import polarisDragHandleIcon from './polaris-icons/DragHandle.vue';
import polarisDuplicateIcon from './polaris-icons/Duplicate.vue';
import polarisEditIcon from './polaris-icons/Edit.vue';
import polarisEmailIcon from './polaris-icons/Email.vue';
import polarisEmailFollowUpIcon from './polaris-icons/EmailFollowUp.vue';
import polarisEmailNewsletterIcon from './polaris-icons/EmailNewsletter.vue';
import polarisEnterIcon from './polaris-icons/Enter.vue';
import polarisEnvelopeIcon from './polaris-icons/Envelope.vue';
import polarisEnvelopeSoftPackIcon from './polaris-icons/EnvelopeSoftPack.vue';
import polarisExchangeIcon from './polaris-icons/Exchange.vue';
import polarisExitIcon from './polaris-icons/Exit.vue';
import polarisExportIcon from './polaris-icons/Export.vue';
import polarisExternalIcon from './polaris-icons/External.vue';
import polarisExternalSmallIcon from './polaris-icons/ExternalSmall.vue';
import polarisEyeCheckMarkIcon from './polaris-icons/EyeCheckMark.vue';
import polarisEyeDropperIcon from './polaris-icons/EyeDropper.vue';
import polarisEyeFirstIcon from './polaris-icons/EyeFirst.vue';
import polarisEyeglassesIcon from './polaris-icons/Eyeglasses.vue';
import polarisFaviconIcon from './polaris-icons/Favicon.vue';
import polarisFileIcon from './polaris-icons/File.vue';
import polarisFileFilledIcon from './polaris-icons/FileFilled.vue';
import polarisFilterIcon from './polaris-icons/Filter.vue';
import polarisFlagIcon from './polaris-icons/Flag.vue';
import polarisFlipHorizontalIcon from './polaris-icons/FlipHorizontal.vue';
import polarisFlipVerticalIcon from './polaris-icons/FlipVertical.vue';
import polarisFlowerIcon from './polaris-icons/Flower.vue';
import polarisFlowerFilledIcon from './polaris-icons/FlowerFilled.vue';
import polarisFolderIcon from './polaris-icons/Folder.vue';
import polarisFolderAddIcon from './polaris-icons/FolderAdd.vue';
import polarisFolderDownIcon from './polaris-icons/FolderDown.vue';
import polarisFolderRemoveIcon from './polaris-icons/FolderRemove.vue';
import polarisFolderUpIcon from './polaris-icons/FolderUp.vue';
import polarisFoodIcon from './polaris-icons/Food.vue';
import polarisForkliftIcon from './polaris-icons/Forklift.vue';
import polarisFormsIcon from './polaris-icons/Forms.vue';
import polarisGamesIcon from './polaris-icons/Games.vue';
import polarisGaugeIcon from './polaris-icons/Gauge.vue';
import polarisGiftCardIcon from './polaris-icons/GiftCard.vue';
import polarisGiftCardFilledIcon from './polaris-icons/GiftCardFilled.vue';
import polarisGlobeIcon from './polaris-icons/Globe.vue';
import polarisGlobeAsiaIcon from './polaris-icons/GlobeAsia.vue';
import polarisGlobeAsiaFilledIcon from './polaris-icons/GlobeAsiaFilled.vue';
import polarisGlobeEuIcon from './polaris-icons/GlobeEU.vue';
import polarisGlobeEuFilledIcon from './polaris-icons/GlobeEUFilled.vue';
import polarisGlobeFilledIcon from './polaris-icons/GlobeFilled.vue';
import polarisHashtagIcon from './polaris-icons/Hashtag.vue';
import polarisHashtagDecimalIcon from './polaris-icons/HashtagDecimal.vue';
import polarisHeartIcon from './polaris-icons/Heart.vue';
import polarisHideIcon from './polaris-icons/Hide.vue';
import polarisHomeIcon from './polaris-icons/Home.vue';
import polarisHomeFilledIcon from './polaris-icons/HomeFilled.vue';
import polarisIconsIcon from './polaris-icons/Icons.vue';
import polarisIconsFilledIcon from './polaris-icons/IconsFilled.vue';
import polarisIdentityCardIcon from './polaris-icons/IdentityCard.vue';
import polarisIdentityCardFilledIcon from './polaris-icons/IdentityCardFilled.vue';
import polarisImageIcon from './polaris-icons/Image.vue';
import polarisImageAddIcon from './polaris-icons/ImageAdd.vue';
import polarisImageAltIcon from './polaris-icons/ImageAlt.vue';
import polarisImageExploreIcon from './polaris-icons/ImageExplore.vue';
import polarisImageMagicIcon from './polaris-icons/ImageMagic.vue';
import polarisImageWithTextOverlayIcon from './polaris-icons/ImageWithTextOverlay.vue';
import polarisImagesIcon from './polaris-icons/Images.vue';
import polarisImportIcon from './polaris-icons/Import.vue';
import polarisIncentiveIcon from './polaris-icons/Incentive.vue';
import polarisIncomingIcon from './polaris-icons/Incoming.vue';
import polarisInfoIcon from './polaris-icons/Info.vue';
import polarisInventoryIcon from './polaris-icons/Inventory.vue';
import polarisInventoryFilledIcon from './polaris-icons/InventoryFilled.vue';
import polarisInventoryUpdatedIcon from './polaris-icons/InventoryUpdated.vue';
import polarisIqIcon from './polaris-icons/Iq.vue';
import polarisKeyIcon from './polaris-icons/Key.vue';
import polarisKeyboardIcon from './polaris-icons/Keyboard.vue';
import polarisKeyboardHideIcon from './polaris-icons/KeyboardHide.vue';
import polarisLabelPrinterIcon from './polaris-icons/LabelPrinter.vue';
import polarisLanguageIcon from './polaris-icons/Language.vue';
import polarisLanguageFilledIcon from './polaris-icons/LanguageFilled.vue';
import polarisLanguageTranslateIcon from './polaris-icons/LanguageTranslate.vue';
import polarisLayoutBlockIcon from './polaris-icons/LayoutBlock.vue';
import polarisLayoutBuyButtonIcon from './polaris-icons/LayoutBuyButton.vue';
import polarisLayoutBuyButtonHorizontalIcon from './polaris-icons/LayoutBuyButtonHorizontal.vue';
import polarisLayoutBuyButtonVerticalIcon from './polaris-icons/LayoutBuyButtonVertical.vue';
import polarisLayoutColumn1Icon from './polaris-icons/LayoutColumn1.vue';
import polarisLayoutColumns2Icon from './polaris-icons/LayoutColumns2.vue';
import polarisLayoutColumns3Icon from './polaris-icons/LayoutColumns3.vue';
import polarisLayoutFooterIcon from './polaris-icons/LayoutFooter.vue';
import polarisLayoutHeaderIcon from './polaris-icons/LayoutHeader.vue';
import polarisLayoutLogoBlockIcon from './polaris-icons/LayoutLogoBlock.vue';
import polarisLayoutPopupIcon from './polaris-icons/LayoutPopup.vue';
import polarisLayoutRows2Icon from './polaris-icons/LayoutRows2.vue';
import polarisLayoutSectionIcon from './polaris-icons/LayoutSection.vue';
import polarisLayoutSidebarLeftIcon from './polaris-icons/LayoutSidebarLeft.vue';
import polarisLayoutSidebarRightIcon from './polaris-icons/LayoutSidebarRight.vue';
import polarisLightbulbIcon from './polaris-icons/Lightbulb.vue';
import polarisLinkIcon from './polaris-icons/Link.vue';
import polarisListBulletedIcon from './polaris-icons/ListBulleted.vue';
import polarisListBulletedFilledIcon from './polaris-icons/ListBulletedFilled.vue';
import polarisListNumberedIcon from './polaris-icons/ListNumbered.vue';
import polarisLiveIcon from './polaris-icons/Live.vue';
import polarisLiveFilledIcon from './polaris-icons/LiveFilled.vue';
import polarisLocationIcon from './polaris-icons/Location.vue';
import polarisLocationFilledIcon from './polaris-icons/LocationFilled.vue';
import polarisLocationNoneIcon from './polaris-icons/LocationNone.vue';
import polarisLockIcon from './polaris-icons/Lock.vue';
import polarisLockFilledIcon from './polaris-icons/LockFilled.vue';
import polarisLogoCriteoIcon from './polaris-icons/LogoCriteo.vue';
import polarisLogoFacebookIcon from './polaris-icons/LogoFacebook.vue';
import polarisLogoGoogleIcon from './polaris-icons/LogoGoogle.vue';
import polarisLogoInstagramIcon from './polaris-icons/LogoInstagram.vue';
import polarisLogoMetaIcon from './polaris-icons/LogoMeta.vue';
import polarisLogoPinterestIcon from './polaris-icons/LogoPinterest.vue';
import polarisLogoSnapchatIcon from './polaris-icons/LogoSnapchat.vue';
import polarisLogoTiktokIcon from './polaris-icons/LogoTiktok.vue';
import polarisLogoTumblrIcon from './polaris-icons/LogoTumblr.vue';
import polarisLogoTwitchIcon from './polaris-icons/LogoTwitch.vue';
import polarisLogoVimeoIcon from './polaris-icons/LogoVimeo.vue';
import polarisLogoXIcon from './polaris-icons/LogoX.vue';
import polarisLogoYoutubeIcon from './polaris-icons/LogoYoutube.vue';
import polarisMagicIcon from './polaris-icons/Magic.vue';
import polarisMarketsIcon from './polaris-icons/Markets.vue';
import polarisMarketsEuroIcon from './polaris-icons/MarketsEuro.vue';
import polarisMarketsEuroFilledIcon from './polaris-icons/MarketsEuroFilled.vue';
import polarisMarketsFilledIcon from './polaris-icons/MarketsFilled.vue';
import polarisMarketsRupeeIcon from './polaris-icons/MarketsRupee.vue';
import polarisMarketsRupeeFilledIcon from './polaris-icons/MarketsRupeeFilled.vue';
import polarisMarketsYenIcon from './polaris-icons/MarketsYen.vue';
import polarisMarketsYenFilledIcon from './polaris-icons/MarketsYenFilled.vue';
import polarisMaximizeIcon from './polaris-icons/Maximize.vue';
import polarisMeasurementSizeIcon from './polaris-icons/MeasurementSize.vue';
import polarisMeasurementVolumeIcon from './polaris-icons/MeasurementVolume.vue';
import polarisMeasurementWeightIcon from './polaris-icons/MeasurementWeight.vue';
import polarisMediaReceiverIcon from './polaris-icons/MediaReceiver.vue';
import polarisMegaphoneIcon from './polaris-icons/Megaphone.vue';
import polarisMegaphoneFilledIcon from './polaris-icons/MegaphoneFilled.vue';
import polarisMentionIcon from './polaris-icons/Mention.vue';
import polarisMenuIcon from './polaris-icons/Menu.vue';
import polarisMenuHorizontalIcon from './polaris-icons/MenuHorizontal.vue';
import polarisMenuVerticalIcon from './polaris-icons/MenuVertical.vue';
import polarisMergeIcon from './polaris-icons/Merge.vue';
import polarisMetafieldsIcon from './polaris-icons/Metafields.vue';
import polarisMetafieldsFilledIcon from './polaris-icons/MetafieldsFilled.vue';
import polarisMetaobjectIcon from './polaris-icons/Metaobject.vue';
import polarisMetaobjectFilledIcon from './polaris-icons/MetaobjectFilled.vue';
import polarisMetaobjectListIcon from './polaris-icons/MetaobjectList.vue';
import polarisMetaobjectReferenceIcon from './polaris-icons/MetaobjectReference.vue';
import polarisMicrophoneIcon from './polaris-icons/Microphone.vue';
import polarisMinimizeIcon from './polaris-icons/Minimize.vue';
import polarisMinusIcon from './polaris-icons/Minus.vue';
import polarisMinusCircleIcon from './polaris-icons/MinusCircle.vue';
import polarisMobileIcon from './polaris-icons/Mobile.vue';
import polarisMoneyIcon from './polaris-icons/Money.vue';
import polarisMoneyFilledIcon from './polaris-icons/MoneyFilled.vue';
import polarisMoneyNoneIcon from './polaris-icons/MoneyNone.vue';
import polarisMoonIcon from './polaris-icons/Moon.vue';
import polarisNatureIcon from './polaris-icons/Nature.vue';
import polarisNoteIcon from './polaris-icons/Note.vue';
import polarisNoteAddIcon from './polaris-icons/NoteAdd.vue';
import polarisNotificationIcon from './polaris-icons/Notification.vue';
import polarisNotificationFilledIcon from './polaris-icons/NotificationFilled.vue';
import polarisOrderIcon from './polaris-icons/Order.vue';
import polarisOrderDraftIcon from './polaris-icons/OrderDraft.vue';
import polarisOrderDraftFilledIcon from './polaris-icons/OrderDraftFilled.vue';
import polarisOrderFilledIcon from './polaris-icons/OrderFilled.vue';
import polarisOrderFirstIcon from './polaris-icons/OrderFirst.vue';
import polarisOrderFulfilledIcon from './polaris-icons/OrderFulfilled.vue';
import polarisOrderRepeatIcon from './polaris-icons/OrderRepeat.vue';
import polarisOrderUnfulfilledIcon from './polaris-icons/OrderUnfulfilled.vue';
import polarisOrdersStatusIcon from './polaris-icons/OrdersStatus.vue';
import polarisOrganizationIcon from './polaris-icons/Organization.vue';
import polarisOrganizationFilledIcon from './polaris-icons/OrganizationFilled.vue';
import polarisOutdentIcon from './polaris-icons/Outdent.vue';
import polarisOutgoingIcon from './polaris-icons/Outgoing.vue';
import polarisPackageIcon from './polaris-icons/Package.vue';
import polarisPackageFilledIcon from './polaris-icons/PackageFilled.vue';
import polarisPackageFulfilledIcon from './polaris-icons/PackageFulfilled.vue';
import polarisPackageOnHoldIcon from './polaris-icons/PackageOnHold.vue';
import polarisPackageReturnedIcon from './polaris-icons/PackageReturned.vue';
import polarisPageIcon from './polaris-icons/Page.vue';
import polarisPageAddIcon from './polaris-icons/PageAdd.vue';
import polarisPageAttachmentIcon from './polaris-icons/PageAttachment.vue';
import polarisPageClockIcon from './polaris-icons/PageClock.vue';
import polarisPageClockFilledIcon from './polaris-icons/PageClockFilled.vue';
import polarisPageDownIcon from './polaris-icons/PageDown.vue';
import polarisPageHeartIcon from './polaris-icons/PageHeart.vue';
import polarisPageReferenceIcon from './polaris-icons/PageReference.vue';
import polarisPageRemoveIcon from './polaris-icons/PageRemove.vue';
import polarisPageUpIcon from './polaris-icons/PageUp.vue';
import polarisPaginationEndIcon from './polaris-icons/PaginationEnd.vue';
import polarisPaginationStartIcon from './polaris-icons/PaginationStart.vue';
import polarisPaintBrushFlatIcon from './polaris-icons/PaintBrushFlat.vue';
import polarisPaintBrushRoundIcon from './polaris-icons/PaintBrushRound.vue';
import polarisPaperCheckIcon from './polaris-icons/PaperCheck.vue';
import polarisParallaxEffectIcon from './polaris-icons/ParallaxEffect.vue';
import polarisPasskeyIcon from './polaris-icons/Passkey.vue';
import polarisPasskeyFilledIcon from './polaris-icons/PasskeyFilled.vue';
import polarisPauseCircleIcon from './polaris-icons/PauseCircle.vue';
import polarisPaymentIcon from './polaris-icons/Payment.vue';
import polarisPaymentCaptureIcon from './polaris-icons/PaymentCapture.vue';
import polarisPaymentFilledIcon from './polaris-icons/PaymentFilled.vue';
import polarisPersonIcon from './polaris-icons/Person.vue';
import polarisPersonAddIcon from './polaris-icons/PersonAdd.vue';
import polarisPersonExitIcon from './polaris-icons/PersonExit.vue';
import polarisPersonFilledIcon from './polaris-icons/PersonFilled.vue';
import polarisPersonLockIcon from './polaris-icons/PersonLock.vue';
import polarisPersonLockFilledIcon from './polaris-icons/PersonLockFilled.vue';
import polarisPersonRemoveIcon from './polaris-icons/PersonRemove.vue';
import polarisPersonSegmentIcon from './polaris-icons/PersonSegment.vue';
import polarisPersonalizedTextIcon from './polaris-icons/PersonalizedText.vue';
import polarisPhoneIcon from './polaris-icons/Phone.vue';
import polarisPhoneInIcon from './polaris-icons/PhoneIn.vue';
import polarisPhoneOutIcon from './polaris-icons/PhoneOut.vue';
import polarisPinIcon from './polaris-icons/Pin.vue';
import polarisPinFilledIcon from './polaris-icons/PinFilled.vue';
import polarisPlanIcon from './polaris-icons/Plan.vue';
import polarisPlanFilledIcon from './polaris-icons/PlanFilled.vue';
import polarisPlayIcon from './polaris-icons/Play.vue';
import polarisPlayCircleIcon from './polaris-icons/PlayCircle.vue';
import polarisPlusIcon from './polaris-icons/Plus.vue';
import polarisPlusCircleIcon from './polaris-icons/PlusCircle.vue';
import polarisPointOfSaleIcon from './polaris-icons/PointOfSale.vue';
import polarisPriceListIcon from './polaris-icons/PriceList.vue';
import polarisPriceListFilledIcon from './polaris-icons/PriceListFilled.vue';
import polarisPrintIcon from './polaris-icons/Print.vue';
import polarisProductIcon from './polaris-icons/Product.vue';
import polarisProductAddIcon from './polaris-icons/ProductAdd.vue';
import polarisProductCostIcon from './polaris-icons/ProductCost.vue';
import polarisProductFilledIcon from './polaris-icons/ProductFilled.vue';
import polarisProductListIcon from './polaris-icons/ProductList.vue';
import polarisProductReferenceIcon from './polaris-icons/ProductReference.vue';
import polarisProductRemoveIcon from './polaris-icons/ProductRemove.vue';
import polarisProductReturnIcon from './polaris-icons/ProductReturn.vue';
import polarisProductUnavailableIcon from './polaris-icons/ProductUnavailable.vue';
import polarisProfileIcon from './polaris-icons/Profile.vue';
import polarisQuestionCircleIcon from './polaris-icons/QuestionCircle.vue';
import polarisReceiptIcon from './polaris-icons/Receipt.vue';
import polarisReceiptDollarIcon from './polaris-icons/ReceiptDollar.vue';
import polarisReceiptDollarFilledIcon from './polaris-icons/ReceiptDollarFilled.vue';
import polarisReceiptEuroIcon from './polaris-icons/ReceiptEuro.vue';
import polarisReceiptEuroFilledIcon from './polaris-icons/ReceiptEuroFilled.vue';
import polarisReceiptPaidIcon from './polaris-icons/ReceiptPaid.vue';
import polarisReceiptPoundIcon from './polaris-icons/ReceiptPound.vue';
import polarisReceiptPoundFilledIcon from './polaris-icons/ReceiptPoundFilled.vue';
import polarisReceiptRefundIcon from './polaris-icons/ReceiptRefund.vue';
import polarisReceiptRupeeIcon from './polaris-icons/ReceiptRupee.vue';
import polarisReceiptRupeeFilledIcon from './polaris-icons/ReceiptRupeeFilled.vue';
import polarisReceiptYenIcon from './polaris-icons/ReceiptYen.vue';
import polarisReceiptYenFilledIcon from './polaris-icons/ReceiptYenFilled.vue';
import polarisRedoIcon from './polaris-icons/Redo.vue';
import polarisReferralCodeIcon from './polaris-icons/ReferralCode.vue';
import polarisRefreshIcon from './polaris-icons/Refresh.vue';
import polarisRemoveBackgroundIcon from './polaris-icons/RemoveBackground.vue';
import polarisReplaceIcon from './polaris-icons/Replace.vue';
import polarisReplayIcon from './polaris-icons/Replay.vue';
import polarisResetIcon from './polaris-icons/Reset.vue';
import polarisReturnIcon from './polaris-icons/Return.vue';
import polarisRewardIcon from './polaris-icons/Reward.vue';
import polarisRotateLeftIcon from './polaris-icons/RotateLeft.vue';
import polarisRotateRightIcon from './polaris-icons/RotateRight.vue';
import polarisSandboxIcon from './polaris-icons/Sandbox.vue';
import polarisSaveIcon from './polaris-icons/Save.vue';
import polarisSearchIcon from './polaris-icons/Search.vue';
import polarisSearchListIcon from './polaris-icons/SearchList.vue';
import polarisSearchRecentIcon from './polaris-icons/SearchRecent.vue';
import polarisSearchResourceIcon from './polaris-icons/SearchResource.vue';
import polarisSelectIcon from './polaris-icons/Select.vue';
import polarisSendIcon from './polaris-icons/Send.vue';
import polarisSettingsIcon from './polaris-icons/Settings.vue';
import polarisSettingsFilledIcon from './polaris-icons/SettingsFilled.vue';
import polarisShareIcon from './polaris-icons/Share.vue';
import polarisShieldCheckMarkIcon from './polaris-icons/ShieldCheckMark.vue';
import polarisShieldNoneIcon from './polaris-icons/ShieldNone.vue';
import polarisShieldPendingIcon from './polaris-icons/ShieldPending.vue';
import polarisShieldPersonIcon from './polaris-icons/ShieldPerson.vue';
import polarisShippingLabelIcon from './polaris-icons/ShippingLabel.vue';
import polarisShippingLabelFilledIcon from './polaris-icons/ShippingLabelFilled.vue';
import polarisShopcodesIcon from './polaris-icons/Shopcodes.vue';
import polarisSidekickIcon from './polaris-icons/Sidekick.vue';
import polarisSkeletonIcon from './polaris-icons/Skeleton.vue';
import polarisSlideshowIcon from './polaris-icons/Slideshow.vue';
import polarisSmileyHappyIcon from './polaris-icons/SmileyHappy.vue';
import polarisSmileyJoyIcon from './polaris-icons/SmileyJoy.vue';
import polarisSmileyNeutralIcon from './polaris-icons/SmileyNeutral.vue';
import polarisSmileySadIcon from './polaris-icons/SmileySad.vue';
import polarisSocialAdIcon from './polaris-icons/SocialAd.vue';
import polarisSocialPostIcon from './polaris-icons/SocialPost.vue';
import polarisSortIcon from './polaris-icons/Sort.vue';
import polarisSortAscendingIcon from './polaris-icons/SortAscending.vue';
import polarisSortDescendingIcon from './polaris-icons/SortDescending.vue';
import polarisSoundIcon from './polaris-icons/Sound.vue';
import polarisSportsIcon from './polaris-icons/Sports.vue';
import polarisStarIcon from './polaris-icons/Star.vue';
import polarisStarFilledIcon from './polaris-icons/StarFilled.vue';
import polarisStatusIcon from './polaris-icons/Status.vue';
import polarisStatusActiveIcon from './polaris-icons/StatusActive.vue';
import polarisStopCircleIcon from './polaris-icons/StopCircle.vue';
import polarisStoreIcon from './polaris-icons/Store.vue';
import polarisStoreFilledIcon from './polaris-icons/StoreFilled.vue';
import polarisStoreImportIcon from './polaris-icons/StoreImport.vue';
import polarisStoreManagedIcon from './polaris-icons/StoreManaged.vue';
import polarisStoreOnlineIcon from './polaris-icons/StoreOnline.vue';
import polarisSunIcon from './polaris-icons/Sun.vue';
import polarisTabletIcon from './polaris-icons/Tablet.vue';
import polarisTargetIcon from './polaris-icons/Target.vue';
import polarisTargetFilledIcon from './polaris-icons/TargetFilled.vue';
import polarisTaxIcon from './polaris-icons/Tax.vue';
import polarisTaxFilledIcon from './polaris-icons/TaxFilled.vue';
import polarisTeamIcon from './polaris-icons/Team.vue';
import polarisTextIcon from './polaris-icons/Text.vue';
import polarisTextAlignCenterIcon from './polaris-icons/TextAlignCenter.vue';
import polarisTextAlignLeftIcon from './polaris-icons/TextAlignLeft.vue';
import polarisTextAlignRightIcon from './polaris-icons/TextAlignRight.vue';
import polarisTextBlockIcon from './polaris-icons/TextBlock.vue';
import polarisTextBoldIcon from './polaris-icons/TextBold.vue';
import polarisTextColorIcon from './polaris-icons/TextColor.vue';
import polarisTextFontIcon from './polaris-icons/TextFont.vue';
import polarisTextFontListIcon from './polaris-icons/TextFontList.vue';
import polarisTextGrammarIcon from './polaris-icons/TextGrammar.vue';
import polarisTextInColumnsIcon from './polaris-icons/TextInColumns.vue';
import polarisTextInRowsIcon from './polaris-icons/TextInRows.vue';
import polarisTextInRowsFilledIcon from './polaris-icons/TextInRowsFilled.vue';
import polarisTextIndentIcon from './polaris-icons/TextIndent.vue';
import polarisTextItalicIcon from './polaris-icons/TextItalic.vue';
import polarisTextQuoteIcon from './polaris-icons/TextQuote.vue';
import polarisTextTitleIcon from './polaris-icons/TextTitle.vue';
import polarisTextUnderlineIcon from './polaris-icons/TextUnderline.vue';
import polarisTextWithImageIcon from './polaris-icons/TextWithImage.vue';
import polarisThemeIcon from './polaris-icons/Theme.vue';
import polarisThemeEditIcon from './polaris-icons/ThemeEdit.vue';
import polarisThemeStoreIcon from './polaris-icons/ThemeStore.vue';
import polarisThemeTemplateIcon from './polaris-icons/ThemeTemplate.vue';
import polarisThumbsDownIcon from './polaris-icons/ThumbsDown.vue';
import polarisThumbsUpIcon from './polaris-icons/ThumbsUp.vue';
import polarisTipJarIcon from './polaris-icons/TipJar.vue';
import polarisToggleOffIcon from './polaris-icons/ToggleOff.vue';
import polarisToggleOnIcon from './polaris-icons/ToggleOn.vue';
import polarisTransactionIcon from './polaris-icons/Transaction.vue';
import polarisTransactionFeeDollarIcon from './polaris-icons/TransactionFeeDollar.vue';
import polarisTransactionFeeEuroIcon from './polaris-icons/TransactionFeeEuro.vue';
import polarisTransactionFeePoundIcon from './polaris-icons/TransactionFeePound.vue';
import polarisTransactionFeeRupeeIcon from './polaris-icons/TransactionFeeRupee.vue';
import polarisTransactionFeeYenIcon from './polaris-icons/TransactionFeeYen.vue';
import polarisTransferIcon from './polaris-icons/Transfer.vue';
import polarisTransferInIcon from './polaris-icons/TransferIn.vue';
import polarisTransferInternalIcon from './polaris-icons/TransferInternal.vue';
import polarisTransferOutIcon from './polaris-icons/TransferOut.vue';
import polarisUndoIcon from './polaris-icons/Undo.vue';
import polarisUnknownDeviceIcon from './polaris-icons/UnknownDevice.vue';
import polarisUploadIcon from './polaris-icons/Upload.vue';
import polarisVariantIcon from './polaris-icons/Variant.vue';
import polarisViewIcon from './polaris-icons/View.vue';
import polarisViewportNarrowIcon from './polaris-icons/ViewportNarrow.vue';
import polarisViewportShortIcon from './polaris-icons/ViewportShort.vue';
import polarisViewportTallIcon from './polaris-icons/ViewportTall.vue';
import polarisViewportWideIcon from './polaris-icons/ViewportWide.vue';
import polarisWalletIcon from './polaris-icons/Wallet.vue';
import polarisWalletFilledIcon from './polaris-icons/WalletFilled.vue';
import polarisWandIcon from './polaris-icons/Wand.vue';
import polarisWatchIcon from './polaris-icons/Watch.vue';
import polarisWifiIcon from './polaris-icons/Wifi.vue';
import polarisWorkIcon from './polaris-icons/Work.vue';
import polarisWorkFilledIcon from './polaris-icons/WorkFilled.vue';
import polarisWrenchIcon from './polaris-icons/Wrench.vue';
import polarisXIcon from './polaris-icons/X.vue';
import polarisXCircleIcon from './polaris-icons/XCircle.vue';
import polarisXSmallIcon from './polaris-icons/XSmall.vue';

export const icons = {
  'polaris-adjust': polarisAdjustIcon,
  'polaris-affiliate': polarisAffiliateIcon,
  'polaris-airplane': polarisAirplaneIcon,
  'polaris-alert-bubble': polarisAlertBubbleIcon,
  'polaris-alert-circle': polarisAlertCircleIcon,
  'polaris-alert-diamond': polarisAlertDiamondIcon,
  'polaris-alert-triangle': polarisAlertTriangleIcon,
  'polaris-app-extension': polarisAppExtensionIcon,
  'polaris-apps': polarisAppsIcon,
  'polaris-apps-filled': polarisAppsFilledIcon,
  'polaris-archive': polarisArchiveIcon,
  'polaris-arrow-diagonal': polarisArrowDiagonalIcon,
  'polaris-arrow-down': polarisArrowDownIcon,
  'polaris-arrow-left': polarisArrowLeftIcon,
  'polaris-arrow-right': polarisArrowRightIcon,
  'polaris-arrow-up': polarisArrowUpIcon,
  'polaris-arrows-in-horizontal': polarisArrowsInHorizontalIcon,
  'polaris-arrows-out-horizontal': polarisArrowsOutHorizontalIcon,
  'polaris-arrows-out-horizontal-filled': polarisArrowsOutHorizontalFilledIcon,
  'polaris-attachment': polarisAttachmentIcon,
  'polaris-attachment-filled': polarisAttachmentFilledIcon,
  'polaris-automation': polarisAutomationIcon,
  'polaris-automation-filled': polarisAutomationFilledIcon,
  'polaris-backspace': polarisBackspaceIcon,
  'polaris-bank': polarisBankIcon,
  'polaris-bank-filled': polarisBankFilledIcon,
  'polaris-barcode': polarisBarcodeIcon,
  'polaris-bill': polarisBillIcon,
  'polaris-bill-filled': polarisBillFilledIcon,
  'polaris-blank': polarisBlankIcon,
  'polaris-blank-filled': polarisBlankFilledIcon,
  'polaris-blog': polarisBlogIcon,
  'polaris-book': polarisBookIcon,
  'polaris-book-open': polarisBookOpenIcon,
  'polaris-bug': polarisBugIcon,
  'polaris-bullet': polarisBulletIcon,
  'polaris-button': polarisButtonIcon,
  'polaris-button-press': polarisButtonPressIcon,
  'polaris-calculator': polarisCalculatorIcon,
  'polaris-calendar': polarisCalendarIcon,
  'polaris-calendar-check': polarisCalendarCheckIcon,
  'polaris-calendar-time': polarisCalendarTimeIcon,
  'polaris-camera': polarisCameraIcon,
  'polaris-camera-flip': polarisCameraFlipIcon,
  'polaris-caret-down': polarisCaretDownIcon,
  'polaris-caret-up': polarisCaretUpIcon,
  'polaris-cart': polarisCartIcon,
  'polaris-cart-abandoned': polarisCartAbandonedIcon,
  'polaris-cart-abandoned-filled': polarisCartAbandonedFilledIcon,
  'polaris-cart-discount': polarisCartDiscountIcon,
  'polaris-cart-down': polarisCartDownIcon,
  'polaris-cart-down-filled': polarisCartDownFilledIcon,
  'polaris-cart-filled': polarisCartFilledIcon,
  'polaris-cart-sale': polarisCartSaleIcon,
  'polaris-cart-up': polarisCartUpIcon,
  'polaris-cash-dollar': polarisCashDollarIcon,
  'polaris-cash-dollar-filled': polarisCashDollarFilledIcon,
  'polaris-cash-euro': polarisCashEuroIcon,
  'polaris-cash-pound': polarisCashPoundIcon,
  'polaris-cash-rupee': polarisCashRupeeIcon,
  'polaris-cash-yen': polarisCashYenIcon,
  'polaris-catalog': polarisCatalogIcon,
  'polaris-categories': polarisCategoriesIcon,
  'polaris-channels': polarisChannelsIcon,
  'polaris-chart-cohort': polarisChartCohortIcon,
  'polaris-chart-donut': polarisChartDonutIcon,
  'polaris-chart-funnel': polarisChartFunnelIcon,
  'polaris-chart-histogram-first': polarisChartHistogramFirstIcon,
  'polaris-chart-histogram-first-last': polarisChartHistogramFirstLastIcon,
  'polaris-chart-histogram-flat': polarisChartHistogramFlatIcon,
  'polaris-chart-histogram-full': polarisChartHistogramFullIcon,
  'polaris-chart-histogram-growth': polarisChartHistogramGrowthIcon,
  'polaris-chart-histogram-last': polarisChartHistogramLastIcon,
  'polaris-chart-histogram-second-last': polarisChartHistogramSecondLastIcon,
  'polaris-chart-horizontal': polarisChartHorizontalIcon,
  'polaris-chart-line': polarisChartLineIcon,
  'polaris-chart-popular': polarisChartPopularIcon,
  'polaris-chart-stacked': polarisChartStackedIcon,
  'polaris-chart-vertical': polarisChartVerticalIcon,
  'polaris-chart-vertical-filled': polarisChartVerticalFilledIcon,
  'polaris-chat': polarisChatIcon,
  'polaris-chat-referral': polarisChatReferralIcon,
  'polaris-check': polarisCheckIcon,
  'polaris-check-circle': polarisCheckCircleIcon,
  'polaris-check-clicked': polarisCheckClickedIcon,
  'polaris-check-hover': polarisCheckHoverIcon,
  'polaris-check-small': polarisCheckSmallIcon,
  'polaris-checkbox': polarisCheckboxIcon,
  'polaris-chevron-down': polarisChevronDownIcon,
  'polaris-chevron-left': polarisChevronLeftIcon,
  'polaris-chevron-right': polarisChevronRightIcon,
  'polaris-chevron-up': polarisChevronUpIcon,
  'polaris-circle-chevron-down': polarisCircleChevronDownIcon,
  'polaris-circle-chevron-left': polarisCircleChevronLeftIcon,
  'polaris-circle-chevron-right': polarisCircleChevronRightIcon,
  'polaris-circle-chevron-up': polarisCircleChevronUpIcon,
  'polaris-circle-down': polarisCircleDownIcon,
  'polaris-circle-left': polarisCircleLeftIcon,
  'polaris-circle-right': polarisCircleRightIcon,
  'polaris-circle-up': polarisCircleUpIcon,
  'polaris-clipboard': polarisClipboardIcon,
  'polaris-clipboard-check': polarisClipboardCheckIcon,
  'polaris-clipboard-check-filled': polarisClipboardCheckFilledIcon,
  'polaris-clipboard-checklist': polarisClipboardChecklistIcon,
  'polaris-clock': polarisClockIcon,
  'polaris-code': polarisCodeIcon,
  'polaris-code-add': polarisCodeAddIcon,
  'polaris-collection': polarisCollectionIcon,
  'polaris-collection-featured': polarisCollectionFeaturedIcon,
  'polaris-collection-filled': polarisCollectionFilledIcon,
  'polaris-collection-list': polarisCollectionListIcon,
  'polaris-collection-reference': polarisCollectionReferenceIcon,
  'polaris-color': polarisColorIcon,
  'polaris-color-none': polarisColorNoneIcon,
  'polaris-compass': polarisCompassIcon,
  'polaris-compose': polarisComposeIcon,
  'polaris-confetti': polarisConfettiIcon,
  'polaris-connect': polarisConnectIcon,
  'polaris-content': polarisContentIcon,
  'polaris-content-filled': polarisContentFilledIcon,
  'polaris-contract': polarisContractIcon,
  'polaris-contract-filled': polarisContractFilledIcon,
  'polaris-corner-pill': polarisCornerPillIcon,
  'polaris-corner-round': polarisCornerRoundIcon,
  'polaris-corner-square': polarisCornerSquareIcon,
  'polaris-credit-card': polarisCreditCardIcon,
  'polaris-credit-card-cancel': polarisCreditCardCancelIcon,
  'polaris-credit-card-percent': polarisCreditCardPercentIcon,
  'polaris-credit-card-reader': polarisCreditCardReaderIcon,
  'polaris-credit-card-reader-chip': polarisCreditCardReaderChipIcon,
  'polaris-credit-card-reader-tap': polarisCreditCardReaderTapIcon,
  'polaris-credit-card-secure': polarisCreditCardSecureIcon,
  'polaris-credit-card-tap-chip': polarisCreditCardTapChipIcon,
  'polaris-crop': polarisCropIcon,
  'polaris-currency-convert': polarisCurrencyConvertIcon,
  'polaris-cursor': polarisCursorIcon,
  'polaris-cursor-banner': polarisCursorBannerIcon,
  'polaris-cursor-filled': polarisCursorFilledIcon,
  'polaris-cursor-option': polarisCursorOptionIcon,
  'polaris-data-presentation': polarisDataPresentationIcon,
  'polaris-data-table': polarisDataTableIcon,
  'polaris-database': polarisDatabaseIcon,
  'polaris-database-add': polarisDatabaseAddIcon,
  'polaris-database-connect': polarisDatabaseConnectIcon,
  'polaris-delete': polarisDeleteIcon,
  'polaris-delivery': polarisDeliveryIcon,
  'polaris-delivery-filled': polarisDeliveryFilledIcon,
  'polaris-desktop': polarisDesktopIcon,
  'polaris-disabled': polarisDisabledIcon,
  'polaris-discount': polarisDiscountIcon,
  'polaris-discount-code': polarisDiscountCodeIcon,
  'polaris-discount-filled': polarisDiscountFilledIcon,
  'polaris-dns-settings': polarisDnsSettingsIcon,
  'polaris-dock-floating': polarisDockFloatingIcon,
  'polaris-dock-side': polarisDockSideIcon,
  'polaris-domain': polarisDomainIcon,
  'polaris-domain-filled': polarisDomainFilledIcon,
  'polaris-domain-landing-page': polarisDomainLandingPageIcon,
  'polaris-domain-new': polarisDomainNewIcon,
  'polaris-domain-redirect': polarisDomainRedirectIcon,
  'polaris-drag-drop': polarisDragDropIcon,
  'polaris-drag-handle': polarisDragHandleIcon,
  'polaris-duplicate': polarisDuplicateIcon,
  'polaris-edit': polarisEditIcon,
  'polaris-email': polarisEmailIcon,
  'polaris-email-follow-up': polarisEmailFollowUpIcon,
  'polaris-email-newsletter': polarisEmailNewsletterIcon,
  'polaris-enter': polarisEnterIcon,
  'polaris-envelope': polarisEnvelopeIcon,
  'polaris-envelope-soft-pack': polarisEnvelopeSoftPackIcon,
  'polaris-exchange': polarisExchangeIcon,
  'polaris-exit': polarisExitIcon,
  'polaris-export': polarisExportIcon,
  'polaris-external': polarisExternalIcon,
  'polaris-external-small': polarisExternalSmallIcon,
  'polaris-eye-check-mark': polarisEyeCheckMarkIcon,
  'polaris-eye-dropper': polarisEyeDropperIcon,
  'polaris-eye-first': polarisEyeFirstIcon,
  'polaris-eyeglasses': polarisEyeglassesIcon,
  'polaris-favicon': polarisFaviconIcon,
  'polaris-file': polarisFileIcon,
  'polaris-file-filled': polarisFileFilledIcon,
  'polaris-filter': polarisFilterIcon,
  'polaris-flag': polarisFlagIcon,
  'polaris-flip-horizontal': polarisFlipHorizontalIcon,
  'polaris-flip-vertical': polarisFlipVerticalIcon,
  'polaris-flower': polarisFlowerIcon,
  'polaris-flower-filled': polarisFlowerFilledIcon,
  'polaris-folder': polarisFolderIcon,
  'polaris-folder-add': polarisFolderAddIcon,
  'polaris-folder-down': polarisFolderDownIcon,
  'polaris-folder-remove': polarisFolderRemoveIcon,
  'polaris-folder-up': polarisFolderUpIcon,
  'polaris-food': polarisFoodIcon,
  'polaris-forklift': polarisForkliftIcon,
  'polaris-forms': polarisFormsIcon,
  'polaris-games': polarisGamesIcon,
  'polaris-gauge': polarisGaugeIcon,
  'polaris-gift-card': polarisGiftCardIcon,
  'polaris-gift-card-filled': polarisGiftCardFilledIcon,
  'polaris-globe': polarisGlobeIcon,
  'polaris-globe-asia': polarisGlobeAsiaIcon,
  'polaris-globe-asia-filled': polarisGlobeAsiaFilledIcon,
  'polaris-globe-eu': polarisGlobeEuIcon,
  'polaris-globe-eu-filled': polarisGlobeEuFilledIcon,
  'polaris-globe-filled': polarisGlobeFilledIcon,
  'polaris-hashtag': polarisHashtagIcon,
  'polaris-hashtag-decimal': polarisHashtagDecimalIcon,
  'polaris-heart': polarisHeartIcon,
  'polaris-hide': polarisHideIcon,
  'polaris-home': polarisHomeIcon,
  'polaris-home-filled': polarisHomeFilledIcon,
  'polaris-icons': polarisIconsIcon,
  'polaris-icons-filled': polarisIconsFilledIcon,
  'polaris-identity-card': polarisIdentityCardIcon,
  'polaris-identity-card-filled': polarisIdentityCardFilledIcon,
  'polaris-image': polarisImageIcon,
  'polaris-image-add': polarisImageAddIcon,
  'polaris-image-alt': polarisImageAltIcon,
  'polaris-image-explore': polarisImageExploreIcon,
  'polaris-image-magic': polarisImageMagicIcon,
  'polaris-image-with-text-overlay': polarisImageWithTextOverlayIcon,
  'polaris-images': polarisImagesIcon,
  'polaris-import': polarisImportIcon,
  'polaris-incentive': polarisIncentiveIcon,
  'polaris-incoming': polarisIncomingIcon,
  'polaris-info': polarisInfoIcon,
  'polaris-inventory': polarisInventoryIcon,
  'polaris-inventory-filled': polarisInventoryFilledIcon,
  'polaris-inventory-updated': polarisInventoryUpdatedIcon,
  'polaris-iq': polarisIqIcon,
  'polaris-key': polarisKeyIcon,
  'polaris-keyboard': polarisKeyboardIcon,
  'polaris-keyboard-hide': polarisKeyboardHideIcon,
  'polaris-label-printer': polarisLabelPrinterIcon,
  'polaris-language': polarisLanguageIcon,
  'polaris-language-filled': polarisLanguageFilledIcon,
  'polaris-language-translate': polarisLanguageTranslateIcon,
  'polaris-layout-block': polarisLayoutBlockIcon,
  'polaris-layout-buy-button': polarisLayoutBuyButtonIcon,
  'polaris-layout-buy-button-horizontal': polarisLayoutBuyButtonHorizontalIcon,
  'polaris-layout-buy-button-vertical': polarisLayoutBuyButtonVerticalIcon,
  'polaris-layout-column-1': polarisLayoutColumn1Icon,
  'polaris-layout-columns-2': polarisLayoutColumns2Icon,
  'polaris-layout-columns-3': polarisLayoutColumns3Icon,
  'polaris-layout-footer': polarisLayoutFooterIcon,
  'polaris-layout-header': polarisLayoutHeaderIcon,
  'polaris-layout-logo-block': polarisLayoutLogoBlockIcon,
  'polaris-layout-popup': polarisLayoutPopupIcon,
  'polaris-layout-rows-2': polarisLayoutRows2Icon,
  'polaris-layout-section': polarisLayoutSectionIcon,
  'polaris-layout-sidebar-left': polarisLayoutSidebarLeftIcon,
  'polaris-layout-sidebar-right': polarisLayoutSidebarRightIcon,
  'polaris-lightbulb': polarisLightbulbIcon,
  'polaris-link': polarisLinkIcon,
  'polaris-list-bulleted': polarisListBulletedIcon,
  'polaris-list-bulleted-filled': polarisListBulletedFilledIcon,
  'polaris-list-numbered': polarisListNumberedIcon,
  'polaris-live': polarisLiveIcon,
  'polaris-live-filled': polarisLiveFilledIcon,
  'polaris-location': polarisLocationIcon,
  'polaris-location-filled': polarisLocationFilledIcon,
  'polaris-location-none': polarisLocationNoneIcon,
  'polaris-lock': polarisLockIcon,
  'polaris-lock-filled': polarisLockFilledIcon,
  'polaris-logo-criteo': polarisLogoCriteoIcon,
  'polaris-logo-facebook': polarisLogoFacebookIcon,
  'polaris-logo-google': polarisLogoGoogleIcon,
  'polaris-logo-instagram': polarisLogoInstagramIcon,
  'polaris-logo-meta': polarisLogoMetaIcon,
  'polaris-logo-pinterest': polarisLogoPinterestIcon,
  'polaris-logo-snapchat': polarisLogoSnapchatIcon,
  'polaris-logo-tiktok': polarisLogoTiktokIcon,
  'polaris-logo-tumblr': polarisLogoTumblrIcon,
  'polaris-logo-twitch': polarisLogoTwitchIcon,
  'polaris-logo-vimeo': polarisLogoVimeoIcon,
  'polaris-logo-x': polarisLogoXIcon,
  'polaris-logo-youtube': polarisLogoYoutubeIcon,
  'polaris-magic': polarisMagicIcon,
  'polaris-markets': polarisMarketsIcon,
  'polaris-markets-euro': polarisMarketsEuroIcon,
  'polaris-markets-euro-filled': polarisMarketsEuroFilledIcon,
  'polaris-markets-filled': polarisMarketsFilledIcon,
  'polaris-markets-rupee': polarisMarketsRupeeIcon,
  'polaris-markets-rupee-filled': polarisMarketsRupeeFilledIcon,
  'polaris-markets-yen': polarisMarketsYenIcon,
  'polaris-markets-yen-filled': polarisMarketsYenFilledIcon,
  'polaris-maximize': polarisMaximizeIcon,
  'polaris-measurement-size': polarisMeasurementSizeIcon,
  'polaris-measurement-volume': polarisMeasurementVolumeIcon,
  'polaris-measurement-weight': polarisMeasurementWeightIcon,
  'polaris-media-receiver': polarisMediaReceiverIcon,
  'polaris-megaphone': polarisMegaphoneIcon,
  'polaris-megaphone-filled': polarisMegaphoneFilledIcon,
  'polaris-mention': polarisMentionIcon,
  'polaris-menu': polarisMenuIcon,
  'polaris-menu-horizontal': polarisMenuHorizontalIcon,
  'polaris-menu-vertical': polarisMenuVerticalIcon,
  'polaris-merge': polarisMergeIcon,
  'polaris-metafields': polarisMetafieldsIcon,
  'polaris-metafields-filled': polarisMetafieldsFilledIcon,
  'polaris-metaobject': polarisMetaobjectIcon,
  'polaris-metaobject-filled': polarisMetaobjectFilledIcon,
  'polaris-metaobject-list': polarisMetaobjectListIcon,
  'polaris-metaobject-reference': polarisMetaobjectReferenceIcon,
  'polaris-microphone': polarisMicrophoneIcon,
  'polaris-minimize': polarisMinimizeIcon,
  'polaris-minus': polarisMinusIcon,
  'polaris-minus-circle': polarisMinusCircleIcon,
  'polaris-mobile': polarisMobileIcon,
  'polaris-money': polarisMoneyIcon,
  'polaris-money-filled': polarisMoneyFilledIcon,
  'polaris-money-none': polarisMoneyNoneIcon,
  'polaris-moon': polarisMoonIcon,
  'polaris-nature': polarisNatureIcon,
  'polaris-note': polarisNoteIcon,
  'polaris-note-add': polarisNoteAddIcon,
  'polaris-notification': polarisNotificationIcon,
  'polaris-notification-filled': polarisNotificationFilledIcon,
  'polaris-order': polarisOrderIcon,
  'polaris-order-draft': polarisOrderDraftIcon,
  'polaris-order-draft-filled': polarisOrderDraftFilledIcon,
  'polaris-order-filled': polarisOrderFilledIcon,
  'polaris-order-first': polarisOrderFirstIcon,
  'polaris-order-fulfilled': polarisOrderFulfilledIcon,
  'polaris-order-repeat': polarisOrderRepeatIcon,
  'polaris-order-unfulfilled': polarisOrderUnfulfilledIcon,
  'polaris-orders-status': polarisOrdersStatusIcon,
  'polaris-organization': polarisOrganizationIcon,
  'polaris-organization-filled': polarisOrganizationFilledIcon,
  'polaris-outdent': polarisOutdentIcon,
  'polaris-outgoing': polarisOutgoingIcon,
  'polaris-package': polarisPackageIcon,
  'polaris-package-filled': polarisPackageFilledIcon,
  'polaris-package-fulfilled': polarisPackageFulfilledIcon,
  'polaris-package-on-hold': polarisPackageOnHoldIcon,
  'polaris-package-returned': polarisPackageReturnedIcon,
  'polaris-page': polarisPageIcon,
  'polaris-page-add': polarisPageAddIcon,
  'polaris-page-attachment': polarisPageAttachmentIcon,
  'polaris-page-clock': polarisPageClockIcon,
  'polaris-page-clock-filled': polarisPageClockFilledIcon,
  'polaris-page-down': polarisPageDownIcon,
  'polaris-page-heart': polarisPageHeartIcon,
  'polaris-page-reference': polarisPageReferenceIcon,
  'polaris-page-remove': polarisPageRemoveIcon,
  'polaris-page-up': polarisPageUpIcon,
  'polaris-pagination-end': polarisPaginationEndIcon,
  'polaris-pagination-start': polarisPaginationStartIcon,
  'polaris-paint-brush-flat': polarisPaintBrushFlatIcon,
  'polaris-paint-brush-round': polarisPaintBrushRoundIcon,
  'polaris-paper-check': polarisPaperCheckIcon,
  'polaris-parallax-effect': polarisParallaxEffectIcon,
  'polaris-passkey': polarisPasskeyIcon,
  'polaris-passkey-filled': polarisPasskeyFilledIcon,
  'polaris-pause-circle': polarisPauseCircleIcon,
  'polaris-payment': polarisPaymentIcon,
  'polaris-payment-capture': polarisPaymentCaptureIcon,
  'polaris-payment-filled': polarisPaymentFilledIcon,
  'polaris-person': polarisPersonIcon,
  'polaris-person-add': polarisPersonAddIcon,
  'polaris-person-exit': polarisPersonExitIcon,
  'polaris-person-filled': polarisPersonFilledIcon,
  'polaris-person-lock': polarisPersonLockIcon,
  'polaris-person-lock-filled': polarisPersonLockFilledIcon,
  'polaris-person-remove': polarisPersonRemoveIcon,
  'polaris-person-segment': polarisPersonSegmentIcon,
  'polaris-personalized-text': polarisPersonalizedTextIcon,
  'polaris-phone': polarisPhoneIcon,
  'polaris-phone-in': polarisPhoneInIcon,
  'polaris-phone-out': polarisPhoneOutIcon,
  'polaris-pin': polarisPinIcon,
  'polaris-pin-filled': polarisPinFilledIcon,
  'polaris-plan': polarisPlanIcon,
  'polaris-plan-filled': polarisPlanFilledIcon,
  'polaris-play': polarisPlayIcon,
  'polaris-play-circle': polarisPlayCircleIcon,
  'polaris-plus': polarisPlusIcon,
  'polaris-plus-circle': polarisPlusCircleIcon,
  'polaris-point-of-sale': polarisPointOfSaleIcon,
  'polaris-price-list': polarisPriceListIcon,
  'polaris-price-list-filled': polarisPriceListFilledIcon,
  'polaris-print': polarisPrintIcon,
  'polaris-product': polarisProductIcon,
  'polaris-product-add': polarisProductAddIcon,
  'polaris-product-cost': polarisProductCostIcon,
  'polaris-product-filled': polarisProductFilledIcon,
  'polaris-product-list': polarisProductListIcon,
  'polaris-product-reference': polarisProductReferenceIcon,
  'polaris-product-remove': polarisProductRemoveIcon,
  'polaris-product-return': polarisProductReturnIcon,
  'polaris-product-unavailable': polarisProductUnavailableIcon,
  'polaris-profile': polarisProfileIcon,
  'polaris-question-circle': polarisQuestionCircleIcon,
  'polaris-receipt': polarisReceiptIcon,
  'polaris-receipt-dollar': polarisReceiptDollarIcon,
  'polaris-receipt-dollar-filled': polarisReceiptDollarFilledIcon,
  'polaris-receipt-euro': polarisReceiptEuroIcon,
  'polaris-receipt-euro-filled': polarisReceiptEuroFilledIcon,
  'polaris-receipt-paid': polarisReceiptPaidIcon,
  'polaris-receipt-pound': polarisReceiptPoundIcon,
  'polaris-receipt-pound-filled': polarisReceiptPoundFilledIcon,
  'polaris-receipt-refund': polarisReceiptRefundIcon,
  'polaris-receipt-rupee': polarisReceiptRupeeIcon,
  'polaris-receipt-rupee-filled': polarisReceiptRupeeFilledIcon,
  'polaris-receipt-yen': polarisReceiptYenIcon,
  'polaris-receipt-yen-filled': polarisReceiptYenFilledIcon,
  'polaris-redo': polarisRedoIcon,
  'polaris-referral-code': polarisReferralCodeIcon,
  'polaris-refresh': polarisRefreshIcon,
  'polaris-remove-background': polarisRemoveBackgroundIcon,
  'polaris-replace': polarisReplaceIcon,
  'polaris-replay': polarisReplayIcon,
  'polaris-reset': polarisResetIcon,
  'polaris-return': polarisReturnIcon,
  'polaris-reward': polarisRewardIcon,
  'polaris-rotate-left': polarisRotateLeftIcon,
  'polaris-rotate-right': polarisRotateRightIcon,
  'polaris-sandbox': polarisSandboxIcon,
  'polaris-save': polarisSaveIcon,
  'polaris-search': polarisSearchIcon,
  'polaris-search-list': polarisSearchListIcon,
  'polaris-search-recent': polarisSearchRecentIcon,
  'polaris-search-resource': polarisSearchResourceIcon,
  'polaris-select': polarisSelectIcon,
  'polaris-send': polarisSendIcon,
  'polaris-settings': polarisSettingsIcon,
  'polaris-settings-filled': polarisSettingsFilledIcon,
  'polaris-share': polarisShareIcon,
  'polaris-shield-check-mark': polarisShieldCheckMarkIcon,
  'polaris-shield-none': polarisShieldNoneIcon,
  'polaris-shield-pending': polarisShieldPendingIcon,
  'polaris-shield-person': polarisShieldPersonIcon,
  'polaris-shipping-label': polarisShippingLabelIcon,
  'polaris-shipping-label-filled': polarisShippingLabelFilledIcon,
  'polaris-shopcodes': polarisShopcodesIcon,
  'polaris-sidekick': polarisSidekickIcon,
  'polaris-skeleton': polarisSkeletonIcon,
  'polaris-slideshow': polarisSlideshowIcon,
  'polaris-smiley-happy': polarisSmileyHappyIcon,
  'polaris-smiley-joy': polarisSmileyJoyIcon,
  'polaris-smiley-neutral': polarisSmileyNeutralIcon,
  'polaris-smiley-sad': polarisSmileySadIcon,
  'polaris-social-ad': polarisSocialAdIcon,
  'polaris-social-post': polarisSocialPostIcon,
  'polaris-sort': polarisSortIcon,
  'polaris-sort-ascending': polarisSortAscendingIcon,
  'polaris-sort-descending': polarisSortDescendingIcon,
  'polaris-sound': polarisSoundIcon,
  'polaris-sports': polarisSportsIcon,
  'polaris-star': polarisStarIcon,
  'polaris-star-filled': polarisStarFilledIcon,
  'polaris-status': polarisStatusIcon,
  'polaris-status-active': polarisStatusActiveIcon,
  'polaris-stop-circle': polarisStopCircleIcon,
  'polaris-store': polarisStoreIcon,
  'polaris-store-filled': polarisStoreFilledIcon,
  'polaris-store-import': polarisStoreImportIcon,
  'polaris-store-managed': polarisStoreManagedIcon,
  'polaris-store-online': polarisStoreOnlineIcon,
  'polaris-sun': polarisSunIcon,
  'polaris-tablet': polarisTabletIcon,
  'polaris-target': polarisTargetIcon,
  'polaris-target-filled': polarisTargetFilledIcon,
  'polaris-tax': polarisTaxIcon,
  'polaris-tax-filled': polarisTaxFilledIcon,
  'polaris-team': polarisTeamIcon,
  'polaris-text': polarisTextIcon,
  'polaris-text-align-center': polarisTextAlignCenterIcon,
  'polaris-text-align-left': polarisTextAlignLeftIcon,
  'polaris-text-align-right': polarisTextAlignRightIcon,
  'polaris-text-block': polarisTextBlockIcon,
  'polaris-text-bold': polarisTextBoldIcon,
  'polaris-text-color': polarisTextColorIcon,
  'polaris-text-font': polarisTextFontIcon,
  'polaris-text-font-list': polarisTextFontListIcon,
  'polaris-text-grammar': polarisTextGrammarIcon,
  'polaris-text-in-columns': polarisTextInColumnsIcon,
  'polaris-text-in-rows': polarisTextInRowsIcon,
  'polaris-text-in-rows-filled': polarisTextInRowsFilledIcon,
  'polaris-text-indent': polarisTextIndentIcon,
  'polaris-text-italic': polarisTextItalicIcon,
  'polaris-text-quote': polarisTextQuoteIcon,
  'polaris-text-title': polarisTextTitleIcon,
  'polaris-text-underline': polarisTextUnderlineIcon,
  'polaris-text-with-image': polarisTextWithImageIcon,
  'polaris-theme': polarisThemeIcon,
  'polaris-theme-edit': polarisThemeEditIcon,
  'polaris-theme-store': polarisThemeStoreIcon,
  'polaris-theme-template': polarisThemeTemplateIcon,
  'polaris-thumbs-down': polarisThumbsDownIcon,
  'polaris-thumbs-up': polarisThumbsUpIcon,
  'polaris-tip-jar': polarisTipJarIcon,
  'polaris-toggle-off': polarisToggleOffIcon,
  'polaris-toggle-on': polarisToggleOnIcon,
  'polaris-transaction': polarisTransactionIcon,
  'polaris-transaction-fee-dollar': polarisTransactionFeeDollarIcon,
  'polaris-transaction-fee-euro': polarisTransactionFeeEuroIcon,
  'polaris-transaction-fee-pound': polarisTransactionFeePoundIcon,
  'polaris-transaction-fee-rupee': polarisTransactionFeeRupeeIcon,
  'polaris-transaction-fee-yen': polarisTransactionFeeYenIcon,
  'polaris-transfer': polarisTransferIcon,
  'polaris-transfer-in': polarisTransferInIcon,
  'polaris-transfer-internal': polarisTransferInternalIcon,
  'polaris-transfer-out': polarisTransferOutIcon,
  'polaris-undo': polarisUndoIcon,
  'polaris-unknown-device': polarisUnknownDeviceIcon,
  'polaris-upload': polarisUploadIcon,
  'polaris-variant': polarisVariantIcon,
  'polaris-view': polarisViewIcon,
  'polaris-viewport-narrow': polarisViewportNarrowIcon,
  'polaris-viewport-short': polarisViewportShortIcon,
  'polaris-viewport-tall': polarisViewportTallIcon,
  'polaris-viewport-wide': polarisViewportWideIcon,
  'polaris-wallet': polarisWalletIcon,
  'polaris-wallet-filled': polarisWalletFilledIcon,
  'polaris-wand': polarisWandIcon,
  'polaris-watch': polarisWatchIcon,
  'polaris-wifi': polarisWifiIcon,
  'polaris-work': polarisWorkIcon,
  'polaris-work-filled': polarisWorkFilledIcon,
  'polaris-wrench': polarisWrenchIcon,
  'polaris-x': polarisXIcon,
  'polaris-x-circle': polarisXCircleIcon,
  'polaris-x-small': polarisXSmallIcon,
};
