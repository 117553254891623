<template>
  <svg width="100%" height="100%" viewBox="0 0 54 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 3C0 1.34315 1.34315 0 3 0L37 0C38.6569 0 40 1.34315 40 3L40 41C40 42.6569 38.6569 44 37 44L3 44C1.34315 44 0 42.6569 0 41L0 3Z" fill="#5E5E5E"/>
<path d="M11 15.7188L16.994 13.6341C16.994 13.6341 17.7228 15.3341 20.006 15.3341C22.2891 15.3341 23.01 13.5996 23.01 13.5996L29 15.7188L28.003 20.9463L24.997 20.9463L24.997 30.3996L14.99 30.3996L14.99 20.9431H11.999L11 15.7188Z" fill="#F9F9F9"/>
<rect x="42" width="12" height="12" rx="3" fill="#5E5E5E"/>
<rect x="42" y="14" width="12" height="12" rx="3" fill="#5E5E5E"/>
<rect x="42" y="28" width="12" height="12" rx="3" fill="#5E5E5E"/>
</svg>
</template>

<script>
export default {
  name: 'ProductImageLayoutRight'
};
</script>
