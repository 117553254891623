<template>
  <svg width="52" height="20" viewBox="0 0 52 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path
        d="M0.5 4C0.5 1.79086 2.29086 0 4.5 0H47.5C49.7091 0 51.5 1.79086 51.5 4V16C51.5 18.2091 49.7091 20 47.5 20H4.5C2.29086 20 0.5 18.2091 0.5 16V4Z"
        fill="#5E5E5E" />
      <rect x="16" y="8" width="20" height="4" rx="2" fill="#F9F9F9" />
    </g>
  </svg>
</template>
