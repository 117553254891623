<template>
  <svg width="100%" height="100%" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 3C0 1.34315 1.34315 0 3 0H47C48.6569 0 50 1.34315 50 3V47C50 48.6569 48.6569 50 47 50H3C1.34315 50 0 48.6569 0 47V3Z" fill="#5E5E5E"/>
<path d="M16 11.4532L21.994 9.36849C21.994 9.36849 22.7228 11.0685 25.006 11.0685C27.2891 11.0685 28.01 9.33398 28.01 9.33398L34 11.4532L33.003 16.6807L29.997 16.6807L29.997 26.134L19.99 26.134L19.99 16.6775H16.999L16 11.4532Z" fill="#F9F9F9"/>
<rect x="5" y="34" width="12" height="12" rx="3" fill="#757575"/>
<rect x="19" y="34" width="12" height="12" rx="3" fill="#757575"/>
<rect x="33" y="34" width="12" height="12" rx="3" fill="#757575"/>
</svg>
</template>

<script>
export default {
  name: 'ProductImageLayoutBottomInside'
};
</script>
