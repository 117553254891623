<template>
  <svg width="100%" height="100%" viewBox="0 0 60 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 4C0 1.79086 1.79086 0 4 0H56C58.2091 0 60 1.79086 60 4V16C60 18.2091 58.2091 20 56 20H4C1.79086 20 0 18.2091 0 16V4Z" fill="#4A4A4A"/>
<rect x="9" y="7.5" width="32" height="5" rx="2.5" fill="#757575"/>
<path d="M50.4046 11.5665C50.1811 11.8112 49.8189 11.8112 49.5954 11.5665L47.5437 9.31966C47.1833 8.92493 47.4386 8.25 47.9483 8.25L52.0517 8.25C52.5614 8.25 52.8167 8.92493 52.4563 9.31966L50.4046 11.5665Z" fill="#D6D6D6"/>
</svg>
</template>

<script>
export default {
  name: 'VariantPresetStyleDropdownSelected'
};
</script>
