<template>
  <perfect-scrollbar
    :options="{ minScrollbarLength: 40 }"
    class="max-h-[380px] px-8"
    :class="[{ 'mt-4': searchable }, wrapperClasses]"
    @scroll="onscroll">
    <template v-for="item in itemsToDisplay" :key="item.id">
      <GTooltip
        placement="top"
        :disabled="!item.value?.tooltip"
        class="!block"
        content-class="max-w-[200px] whitespace-normal"
        :is-teleport="true">
        <slot :item="item"></slot>
        <template #content>
          {{ item.value?.tooltip }}
        </template>
      </GTooltip>
      <slot name="content" :is-hover-select="isHoverSelect" :on-select="onSelect"> </slot>
    </template>
  </perfect-scrollbar>
  <template v-if="loading">
    <!-- Loading component -->
    <div id="loading-wrapper">
      <slot name="loading"></slot>
    </div>
  </template>
  <!-- list footer -->
  <div v-show="page !== items.length - 1 || !loading" ref="endOfList" />
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from 'vue';
import GTooltip from './GTooltip.vue';

const props = withDefaults(
  defineProps<{
    searchable?: boolean;
    data: any[];
    itemsPerRender?: number;
    delay?: number;
    isHoverSelect?: boolean;
    onSelect?: Function;
    wrapperClasses?: string;
  }>(),
  {
    searchable: false,
    data: () => [],
    itemsPerRender: 15,
    delay: 500,
  },
);

const items = ref<any[]>([]);
const page = ref(0);
const loading = ref(false);
const itemsToDisplay = ref<any[]>([]);
const endOfList = ref<HTMLElement | null>(null);

const updateList = () => {
  if (props.data.length <= 0) {
    items.value = [];
    itemsToDisplay.value = [];

    return;
  }

  const chunckedArray = chunkArray(props.data, props.itemsPerRender);
  items.value = chunckedArray;
  itemsToDisplay.value = chunckedArray[0];
};

const onscroll = () => {
  loadItems();
};

const loadItems = () => {
  if (page.value >= items.value.length - 1) return;

  const element = endOfList.value;
  if (!element) return;

  const position = element.getBoundingClientRect();

  // checking whether fully visible
  if (position.top >= 0 && position.bottom <= window.innerHeight && !loading.value) {
    loading.value = true;
    page.value++;
    setTimeout(() => {
      loading.value = false;

      if (itemsToDisplay.value.length > 0 && items.value.length > 0) {
        itemsToDisplay.value = [...itemsToDisplay.value, ...items.value[page.value]];
        loadItems();
      }
    }, props.delay);
  }
};

const chunkArray = (arr: any, size: number): any[] => {
  return Array.from({ length: Math.ceil(arr.length / size) }, (_, i) => arr.slice(i * size, i * size + size));
};

onMounted(() => {
  updateList();
  loadItems();
});

watchEffect(() => {
  updateList();
});
</script>
