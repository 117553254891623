<template>
  <svg width="100%" height="100%" viewBox="0 0 64 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 4C0 1.79086 1.79086 0 4 0H60C62.2091 0 64 1.79086 64 4V36C64 38.2091 62.2091 40 60 40H4C1.79086 40 0 38.2091 0 36V4Z" fill="#333333"/>
  <circle cx="18" cy="20" r="10" fill="#D6D6D6"/>
  <circle cx="46" cy="20" r="10" fill="#757575"/>
</svg>
</template>

<script>
export default {
  name: 'VariantPresetStyleColor'
};
</script>
