<template>
  <svg width="100%" height="100%" viewBox="0 0 50 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="4" y="3.5" width="16" height="4" rx="2" fill="#F9F9F9"/>
  <rect x="4" y="10.5" width="42" height="10" rx="2" fill="#5E5E5E"/>
</svg>
</template>

<script>
export default {
  name: 'ArrangeVertical'
};
</script>
