<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path d="M12.9998 8C13.552 8 13.9998 7.55228 13.9998 7C13.9998 6.44772 13.552 6 12.9998 6C12.4475 6 11.9998 6.44772 11.9998 7C11.9998 7.55228 12.4475 8 12.9998 8Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.2757 3.5C10.2566 3.5 9.28138 3.91477 8.57449 4.64885L4.32088 9.06605C3.28141 10.1455 3.29757 11.8585 4.35722 12.9181L7.25512 15.816C8.21844 16.7793 9.77568 16.794 10.757 15.8491L15.5041 11.2778C16.1403 10.6651 16.4998 9.81995 16.4998 8.93673V6.75C16.4998 4.95507 15.0447 3.5 13.2498 3.5H11.2757ZM9.65497 5.68931C10.0791 5.24886 10.6642 5 11.2757 5H13.2498C14.2163 5 14.9998 5.7835 14.9998 6.75V8.93673C14.9998 9.41231 14.8062 9.86741 14.4636 10.1973L9.71653 14.7686C9.32401 15.1466 8.70111 15.1407 8.31578 14.7554L5.41788 11.8575C4.93622 11.3758 4.92887 10.5972 5.40136 10.1065L9.65497 5.68931Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'ProductTagCondition'
};
</script>
