<template>
  <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.75 10C18.75 14.8325 14.8325 18.75 10 18.75C5.16751 18.75 1.25 14.8325 1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10ZM10.5835 5.76996C10.5835 6.11514 10.3037 6.39496 9.9585 6.39496C9.61332 6.39496 9.3335 6.11514 9.3335 5.76996C9.3335 5.42478 9.61332 5.14496 9.9585 5.14496C10.3037 5.14496 10.5835 5.42478 10.5835 5.76996ZM8.64136 6.98291C8.36521 6.98291 8.14136 7.20677 8.14136 7.48291C8.14136 7.75905 8.36521 7.98291 8.64136 7.98291H9.57507V13.1049C9.57507 13.6572 10.0228 14.1049 10.5751 14.1049H11.9643C12.2404 14.1049 12.4643 13.881 12.4643 13.6049C12.4643 13.3288 12.2404 13.1049 11.9643 13.1049H10.5751V7.98291C10.5751 7.43063 10.1274 6.98291 9.57507 6.98291H8.64136Z" fill="#8AA4FF"/>
</svg>
</template>

<script>
export default {
  name: 'Info'
};
</script>
