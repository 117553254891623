<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.7998 5.19999C5.46843 5.19999 5.1998 5.46862 5.1998 5.79999C5.1998 6.13136 5.46843 6.39999 5.7998 6.39999H10.1998C10.5312 6.39999 10.7998 6.13136 10.7998 5.79999C10.7998 5.46862 10.5312 5.19999 10.1998 5.19999H5.7998Z"
      fill="#F9F9F9" />
    <path
      d="M5.1998 7.99999C5.1998 7.66862 5.46843 7.39999 5.7998 7.39999H10.1998C10.5312 7.39999 10.7998 7.66862 10.7998 7.99999C10.7998 8.33136 10.5312 8.59999 10.1998 8.59999H5.7998C5.46843 8.59999 5.1998 8.33136 5.1998 7.99999Z"
      fill="#F9F9F9" />
    <path
      d="M5.7998 9.59999C5.46843 9.59999 5.1998 9.86862 5.1998 10.2C5.1998 10.5314 5.46843 10.8 5.7998 10.8H8.5998C8.93118 10.8 9.1998 10.5314 9.1998 10.2C9.1998 9.86862 8.93118 9.59999 8.5998 9.59999H5.7998Z"
      fill="#F9F9F9" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.7998 2.79999C4.14295 2.79999 2.7998 4.14313 2.7998 5.79999V10.2C2.7998 11.8568 4.14295 13.2 5.7998 13.2H10.1998C11.8567 13.2 13.1998 11.8568 13.1998 10.2V5.79999C13.1998 4.14313 11.8567 2.79999 10.1998 2.79999H5.7998ZM3.9998 5.79999C3.9998 4.80588 4.80569 3.99999 5.7998 3.99999H10.1998C11.1939 3.99999 11.9998 4.80588 11.9998 5.79999V10.2C11.9998 11.1941 11.1939 12 10.1998 12H5.7998C4.80569 12 3.9998 11.1941 3.9998 10.2V5.79999Z"
      fill="#F9F9F9" />
  </svg>
</template>

<script>
export default {
  name: 'PrefixAuthor',
};
</script>
