<template>
  <svg width="100%" height="100%" viewBox="0 0 94 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 14C0 10.6863 2.68629 8 6 8H34C37.3137 8 40 10.6863 40 14V42C40 45.3137 37.3137 48 34 48H6C2.68629 48 0 45.3137 0 42V14Z" fill="#5E5E5E"/>
<path d="M12.5 22.766L17.495 21.0288C17.495 21.0288 18.1024 22.4454 20.005 22.4454C21.9076 22.4454 22.5083 21 22.5083 21L27.5 22.766L26.6692 27.1222H24.1642V35H15.825V27.1196H13.3325L12.5 22.766Z" fill="#D6D6D6"/>
<rect x="48" y="15" width="46" height="6" rx="3" fill="#5E5E5E"/>
<rect x="48" y="25" width="42" height="6" rx="3" fill="#5E5E5E"/>
<rect x="48" y="35" width="24" height="6" rx="3" fill="#5E5E5E"/>
</svg>
</template>

<script>
export default {
  name: 'ProductLayoutHorizontal'
};
</script>
