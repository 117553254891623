<template>
  <svg width="100%" height="100%" viewBox="0 0 60 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V16C28 18.2091 26.2091 20 24 20H4C1.79086 20 0 18.2091 0 16V4Z" fill="#4A4A4A"/>
<circle cx="8.5" cy="6" r="2" fill="#D6D6D6"/>
<path d="M6.69685 14.6453L8.90968 12.0595C8.9846 11.9718 9.07719 11.9008 9.18141 11.8512C9.28562 11.8017 9.39913 11.7746 9.51452 11.7718C9.62991 11.769 9.7446 11.7905 9.8511 11.835C9.95761 11.8795 10.0535 11.9458 10.1326 12.0298L11.1673 13.1271L15.212 8.29464C15.2912 8.19995 15.3908 8.1243 15.5033 8.07333C15.6157 8.02236 15.7383 7.99737 15.8618 8.00022C15.9852 8.00307 16.1065 8.03369 16.2165 8.08979C16.3265 8.1459 16.4224 8.22605 16.4972 8.32429L21.3329 14.6772C21.4254 14.7989 21.482 14.9441 21.4964 15.0962C21.5107 15.2484 21.4823 15.4016 21.4144 15.5385C21.3464 15.6754 21.2415 15.7907 21.1115 15.8713C20.9815 15.952 20.8316 15.9948 20.6786 15.995L7.32205 16C7.16495 15.9999 7.01115 15.9549 6.87885 15.8703C6.74656 15.7857 6.6413 15.6649 6.57553 15.5224C6.50976 15.3799 6.48624 15.2216 6.50774 15.0661C6.52924 14.9107 6.59487 14.7646 6.69685 14.6453Z" fill="#D6D6D6"/>
<path d="M32 4C32 1.79086 33.7909 0 36 0H56C58.2091 0 60 1.79086 60 4V16C60 18.2091 58.2091 20 56 20H36C33.7909 20 32 18.2091 32 16V4Z" fill="#4A4A4A"/>
<circle cx="40.5" cy="6" r="2" fill="#D6D6D6"/>
<path d="M38.6969 14.6453L40.9097 12.0595C40.9846 11.9718 41.0772 11.9008 41.1814 11.8512C41.2856 11.8017 41.3991 11.7746 41.5145 11.7718C41.6299 11.769 41.7446 11.7905 41.8511 11.835C41.9576 11.8795 42.0535 11.9458 42.1326 12.0298L43.1673 13.1271L47.212 8.29464C47.2912 8.19995 47.3908 8.1243 47.5033 8.07333C47.6157 8.02236 47.7383 7.99737 47.8618 8.00022C47.9852 8.00307 48.1065 8.03369 48.2165 8.08979C48.3265 8.1459 48.4224 8.22605 48.4972 8.32429L53.3329 14.6772C53.4254 14.7989 53.482 14.9441 53.4964 15.0962C53.5107 15.2484 53.4823 15.4016 53.4144 15.5385C53.3464 15.6754 53.2415 15.7907 53.1115 15.8713C52.9815 15.952 52.8316 15.9948 52.6786 15.995L39.322 16C39.1649 15.9999 39.0111 15.9549 38.8789 15.8703C38.7466 15.7857 38.6413 15.6649 38.5755 15.5224C38.5098 15.3799 38.4862 15.2216 38.5077 15.0661C38.5292 14.9107 38.5949 14.7646 38.6969 14.6453Z" fill="#D6D6D6"/>
</svg>
</template>

<script>
export default {
  name: 'VariantPresetStyleImageSelected'
};
</script>
