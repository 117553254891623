<template>
  <svg width="100%" height="100%" viewBox="0 0 60 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V16C28 18.2091 26.2091 20 24 20H4C1.79086 20 0 18.2091 0 16V4Z" fill="#4A4A4A"/>
<rect x="6" y="7.5" width="16" height="5" rx="2.5" fill="#D6D6D6"/>
<path d="M32 4C32 1.79086 33.7909 0 36 0H56C58.2091 0 60 1.79086 60 4V16C60 18.2091 58.2091 20 56 20H36C33.7909 20 32 18.2091 32 16V4Z" fill="#4A4A4A"/>
<rect x="38" y="7.5" width="16" height="5" rx="2.5" fill="#757575"/>
</svg>
</template>

<script>
export default {
  name: 'VariantPresetStyleListSelected'
};
</script>
