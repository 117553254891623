<template>
  <svg width="100%" height="100%" viewBox="0 0 64 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 4C0 1.79086 1.79086 0 4 0H60C62.2091 0 64 1.79086 64 4V36C64 38.2091 62.2091 40 60 40H4C1.79086 40 0 38.2091 0 36V4Z" fill="#333333"/>
  <rect x="4" y="7" width="26" height="26" rx="3" fill="#474747"/>
  <path d="M9 14.3922L14.328 12.5308C14.328 12.5308 14.9759 14.0486 17.0053 14.0486C19.0348 14.0486 19.6755 12.5 19.6755 12.5L25 14.3922L24.1138 19.0595H21.4418V27.5H12.5467V19.0567H9.888L9 14.3922Z" fill="#D6D6D6"/>
  <rect x="34" y="7" width="26" height="26" rx="3" fill="#474747"/>
  <path d="M39 14.3922L44.328 12.5308C44.328 12.5308 44.9759 14.0486 47.0053 14.0486C49.0348 14.0486 49.6755 12.5 49.6755 12.5L55 14.3922L54.1138 19.0595H51.4418V27.5H42.5467V19.0567H39.888L39 14.3922Z" fill="#D6D6D6"/>
</svg>
</template>

<script>
export default {
  name: 'VariantPresetStyleImageShopify'
};
</script>
