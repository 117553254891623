<template>
  <svg width="100%" height="100%" viewBox="0 0 102 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.24626 12.8286C8.45128 13.0336 8.45128 13.366 8.24626 13.571L5.81749 15.9998L8.24626 18.4286C8.45128 18.6336 8.45128 18.966 8.24626 19.171C8.04123 19.3761 7.70882 19.3761 7.50379 19.171L4.70379 16.371C4.49877 16.166 4.49877 15.8336 4.70379 15.6286L7.50379 12.8286C7.70882 12.6235 8.04123 12.6235 8.24626 12.8286Z" fill="#D6D6D6"/>
<path d="M18 3C18 1.34315 19.3431 0 21 0H47C48.6569 0 50 1.34315 50 3V29C50 30.6569 48.6569 32 47 32H21C19.3431 32 18 30.6569 18 29V3Z" fill="#5E5E5E"/>
<path d="M26.5 10.266L31.495 8.52875C31.495 8.52875 32.1024 9.94539 34.005 9.94539C35.9076 9.94539 36.5083 8.5 36.5083 8.5L41.5 10.266L40.6692 14.6222H38.1642V22.5H29.825V14.6196H27.3325L26.5 10.266Z" fill="#D6D6D6"/>
<path d="M52 3C52 1.34315 53.3431 0 55 0H81C82.6569 0 84 1.34315 84 3V29C84 30.6569 82.6569 32 81 32H55C53.3431 32 52 30.6569 52 29V3Z" fill="#5E5E5E"/>
<path d="M60.5 10.266L65.495 8.52875C65.495 8.52875 66.1024 9.94539 68.005 9.94539C69.9076 9.94539 70.5083 8.5 70.5083 8.5L75.5 10.266L74.6692 14.6222H72.1642V22.5H63.825V14.6196H61.3325L60.5 10.266Z" fill="#D6D6D6"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M93.4038 19.1714C93.1987 18.9664 93.1987 18.634 93.4038 18.429L95.8325 16.0002L93.4038 13.5714C93.1987 13.3664 93.1987 13.034 93.4038 12.829C93.6088 12.6239 93.9412 12.6239 94.1462 12.829L96.9462 15.629C97.1513 15.834 97.1513 16.1664 96.9462 16.3714L94.1462 19.1714C93.9412 19.3765 93.6088 19.3765 93.4038 19.1714Z" fill="#D6D6D6"/>
</svg>
</template>

<script>
export default {
  name: 'SliderLayout'
};
</script>
