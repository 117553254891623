<template>
  <svg width="100%" height="100%" viewBox="0 0 94 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 3C0 1.34315 1.34315 0 3 0H91C92.6569 0 94 1.34315 94 3C94 4.65685 92.6569 6 91 6H3C1.34315 6 0 4.65685 0 3Z" fill="#5E5E5E"/>
<path d="M0 3C0 1.34315 1.34315 0 3 0H66.2253C67.8821 0 69.2253 1.34315 69.2253 3C69.2253 4.65685 67.8821 6 66.2253 6H3C1.34314 6 0 4.65685 0 3Z" fill="#D6D6D6"/>
</svg>
</template>

<script>
export default {
  name: 'StockCounterProgressBar'
};
</script>
