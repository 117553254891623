<template>
  <svg width="100%" height="100%" viewBox="0 0 64 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 16C0.5 7.43959 7.43959 0.5 16 0.5H48C56.5604 0.5 63.5 7.43959 63.5 16C63.5 24.5604 56.5604 31.5 48 31.5H16C7.43959 31.5 0.5 24.5604 0.5 16Z" stroke="#5E5E5E"/>
<path d="M33.2051 12V20H31.9941V13.2109H31.9473L30.0332 14.4609V13.3047L32.0293 12H33.2051Z" fill="white"/>
</svg>
</template>

<script>
export default {
  name: 'ProductQuantityRoundedInputOnly'
};
</script>
