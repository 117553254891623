<template>
  <svg width="52" height="20" viewBox="0 0 52 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path
        d="M0.5 4C0.5 1.79086 2.29086 0 4.5 0H47.5C49.7091 0 51.5 1.79086 51.5 4V16C51.5 18.2091 49.7091 20 47.5 20H4.5C2.29086 20 0.5 18.2091 0.5 16V4Z"
        fill="#5E5E5E" />
      <rect x="9" y="8" width="20" height="4" rx="2" fill="#F9F9F9" />
      <g clip-path="url(#clip0_16423_5169)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M42.331 5.66897C39.9391 3.27701 36.0609 3.27701 33.669 5.66897C31.277 8.06093 31.277 11.9391 33.669 14.331C36.0609 16.723 39.9391 16.723 42.331 14.331C44.723 11.9391 44.723 8.06093 42.331 5.66897ZM40.9115 7.41919C41.0994 7.57102 41.1287 7.84647 40.9769 8.03444L37.5258 12.3071C37.4502 12.4008 37.3392 12.4591 37.2191 12.4684C37.099 12.4776 36.9804 12.4369 36.8913 12.3559L35.0569 10.689C34.878 10.5265 34.8648 10.2498 35.0273 10.071C35.1898 9.89218 35.4665 9.87895 35.6453 10.0414L37.1365 11.3965L40.2962 7.48463C40.4481 7.29667 40.7235 7.26737 40.9115 7.41919Z"
          fill="#F9F9F9" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_16423_5169">
        <rect width="14" height="14" fill="white" transform="translate(31 3)" />
      </clipPath>
    </defs>
  </svg>
</template>
