<template>
  <svg width="100%" height="100%" viewBox="0 0 64 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 4C0 1.79086 1.79086 0 4 0H60C62.2091 0 64 1.79086 64 4V36C64 38.2091 62.2091 40 60 40H4C1.79086 40 0 38.2091 0 36V4Z" fill="#333333"/>
  <rect x="4" y="8" width="56" height="24" rx="3" fill="#424242"/>
  <rect x="8.5" y="17" width="37" height="6" rx="2" fill="#757575"/>
  <path d="M52.9046 21.5665C52.6811 21.8112 52.3189 21.8112 52.0954 21.5665L50.0437 19.3197C49.6833 18.9249 49.9386 18.25 50.4483 18.25H54.5517C55.0614 18.25 55.3167 18.9249 54.9563 19.3197L52.9046 21.5665Z" fill="#D6D6D6"/>
</svg>
</template>

<script>
export default {
  name: 'VariantPresetStyleDropdown'
};
</script>
