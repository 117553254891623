<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6 1.75C6.41421 1.75 6.75 2.08579 6.75 2.5V3.02752C7.23694 2.99999 7.81936 2.99999 8.51789 3L11 3V2.5C11 2.08579 11.3358 1.75 11.75 1.75C12.1642 1.75 12.5 2.08579 12.5 2.5V3.10358C13.7959 3.45124 14.75 4.63402 14.75 6.03964C14.75 6.87537 14.0725 7.55286 13.2368 7.55286H4.50359C4.50012 7.84142 4.5 8.17006 4.5 8.55V11.0179C4.5 12.2506 5.49936 13.25 6.73214 13.25C7.14636 13.25 7.48214 13.5858 7.48214 14C7.48214 14.4142 7.14636 14.75 6.73214 14.75C4.67094 14.75 3 13.0791 3 11.0179V8.5179C2.99999 7.70506 2.99999 7.04944 3.04336 6.51853C3.08803 5.9719 3.18239 5.49175 3.40873 5.04754C3.76825 4.34193 4.34193 3.76825 5.04754 3.40873C5.11418 3.37477 5.18163 3.34378 5.25 3.31549V2.5C5.25 2.08579 5.58579 1.75 6 1.75ZM13.2368 6.05286H4.62667C4.65952 5.92307 4.69906 5.81914 4.74524 5.72852C4.96095 5.30516 5.30516 4.96095 5.72852 4.74524C5.92604 4.6446 6.18681 4.57547 6.64068 4.53838C7.10331 4.50058 7.69755 4.5 8.55 4.5H11.7104C12.5607 4.5 13.25 5.18932 13.25 6.03964C13.25 6.04283 13.2495 6.04401 13.2493 6.04456C13.2488 6.0456 13.2479 6.04725 13.2461 6.04899C13.2444 6.05073 13.2427 6.05169 13.2417 6.05213C13.2411 6.05236 13.24 6.05286 13.2368 6.05286Z" fill="currentColor"/>
<path d="M14.25 12C14.25 11.5858 13.9142 11.25 13.5 11.25C13.0858 11.25 12.75 11.5858 12.75 12V13.2929C12.75 13.6244 12.8817 13.9424 13.1161 14.1768L13.9697 15.0303C14.2626 15.3232 14.7374 15.3232 15.0303 15.0303C15.3232 14.7374 15.3232 14.2626 15.0303 13.9697L14.25 13.1893V12Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 18C15.9853 18 18 15.9853 18 13.5C18 11.0147 15.9853 9 13.5 9C11.0147 9 9 11.0147 9 13.5C9 15.9853 11.0147 18 13.5 18ZM13.5 16.5C15.1569 16.5 16.5 15.1569 16.5 13.5C16.5 11.8431 15.1569 10.5 13.5 10.5C11.8431 10.5 10.5 11.8431 10.5 13.5C10.5 15.1569 11.8431 16.5 13.5 16.5Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'CreatedDateCondition'
};
</script>
