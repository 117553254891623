<template>
  <svg width="100%" height="100%" viewBox="0 0 40 74" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_13801_81772)">
<rect y="16" width="40" height="40" rx="3" fill="#5E5E5E"/>
<path d="M10 29.0187L16.66 26.7024C16.66 26.7024 17.4698 28.5913 20.0066 28.5913C22.5435 28.5913 23.3444 26.6641 23.3444 26.6641L30 29.0187L28.8922 34.827H25.5522L25.5522 45.3307H14.4334L14.4334 34.8235H11.11L10 29.0187Z" fill="#F9F9F9"/>
<rect y="60" width="40" height="40" rx="3" fill="#5E5E5E"/>
</g>
</svg>
</template>

<script>
export default {
  name: 'ProductImageLayoutOneCol'
};
</script>
