<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.55999 5.31984C2.55999 4.06063 3.58078 3.03984 4.83999 3.03984H9.63999C10.8992 3.03984 11.92 4.06063 11.92 5.31984V5.79984C11.92 6.13121 12.1886 6.39984 12.52 6.39984C12.8514 6.39984 13.12 6.13121 13.12 5.79984V5.31984C13.12 3.39789 11.5619 1.83984 9.63999 1.83984H4.83999C2.91804 1.83984 1.35999 3.39789 1.35999 5.31984V10.1198C1.35999 12.0418 2.91804 13.5998 4.83999 13.5998H6.27998C6.61136 13.5998 6.87999 13.3312 6.87999 12.9998C6.87999 12.6685 6.61136 12.3998 6.27998 12.3998H4.83999C3.58078 12.3998 2.55999 11.3791 2.55999 10.1198V5.31984ZM7.94282 6.68276C7.71997 6.59362 7.46544 6.64586 7.29572 6.81558C7.126 6.9853 7.07376 7.23983 7.1629 7.46268L9.8509 14.1827C9.94218 14.4109 10.1634 14.5603 10.4092 14.5598C10.655 14.5593 10.8756 14.409 10.966 14.1804L12.0128 11.5326L14.6606 10.4858C14.8891 10.3955 15.0395 10.1748 15.04 9.92905C15.0405 9.68327 14.891 9.46204 14.6628 9.37076L7.94282 6.68276ZM10.4047 12.3361L8.79702 8.31688L12.8163 9.92458L11.3298 10.5123C11.1755 10.5733 11.0534 10.6954 10.9924 10.8496L10.4047 12.3361Z"
      fill="#F9F9F9" />
  </svg>
</template>

<script>
export default {
  name: 'CheckClicked',
};
</script>
