<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path d="M13.2799 8.28034C13.5728 7.98745 13.5728 7.51257 13.2799 7.21968C12.987 6.92679 12.5121 6.92679 12.2192 7.21968L7.7192 11.7197C7.4263 12.0126 7.4263 12.4874 7.7192 12.7803C8.01209 13.0732 8.48696 13.0732 8.77986 12.7803L13.2799 8.28034Z" fill="currentColor"/>
<path d="M9.49953 8.00001C9.49953 8.5523 9.05181 9.00001 8.49953 9.00001C7.94724 9.00001 7.49953 8.5523 7.49953 8.00001C7.49953 7.44773 7.94724 7.00001 8.49953 7.00001C9.05181 7.00001 9.49953 7.44773 9.49953 8.00001Z" fill="currentColor"/>
<path d="M12.4995 13C13.0518 13 13.4995 12.5523 13.4995 12C13.4995 11.4477 13.0518 11 12.4995 11C11.9472 11 11.4995 11.4477 11.4995 12C11.4995 12.5523 11.9472 13 12.4995 13Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.5939 3.51441C11.7718 1.72371 9.22726 1.72371 8.40519 3.51441C8.22962 3.89684 7.78899 4.07936 7.39442 3.93308C5.54692 3.24816 3.74767 5.04741 4.43259 6.89491C4.57887 7.28948 4.39635 7.73011 4.01392 7.90568C2.22323 8.72775 2.22323 11.2723 4.01392 12.0943C4.39635 12.2699 4.57887 12.7105 4.43259 13.1051C3.74767 14.9526 5.54692 16.7519 7.39442 16.0669C7.78899 15.9207 8.22962 16.1032 8.40519 16.4856C9.22726 18.2763 11.7718 18.2763 12.5939 16.4856C12.7694 16.1032 13.2101 15.9207 13.6046 16.0669C15.4521 16.7519 17.2514 14.9526 16.5665 13.1051C16.4202 12.7105 16.6027 12.2699 16.9851 12.0943C18.7758 11.2723 18.7758 8.72775 16.9851 7.90568C16.6027 7.73011 16.4202 7.28948 16.5665 6.89491C17.2514 5.04741 15.4521 3.24816 13.6046 3.93308C13.2101 4.07936 12.7694 3.89684 12.5939 3.51441ZM9.7684 4.14023C10.0554 3.51511 10.9437 3.51511 11.2306 4.14023C11.7336 5.23573 12.9958 5.75856 14.126 5.33954C14.771 5.10044 15.3991 5.72855 15.16 6.3735C14.741 7.50376 15.2638 8.76597 16.3593 9.26889C16.9844 9.55587 16.9844 10.4441 16.3593 10.7311C15.2638 11.2341 14.741 12.4963 15.16 13.6265C15.3991 14.2715 14.771 14.8996 14.126 14.6605C12.9958 14.2415 11.7336 14.7643 11.2306 15.8598C10.9437 16.4849 10.0554 16.4849 9.7684 15.8598C9.26548 14.7643 8.00327 14.2415 6.87301 14.6605C6.22806 14.8996 5.59995 14.2715 5.83905 13.6265C6.25807 12.4963 5.73524 11.2341 4.63974 10.7311C4.01462 10.4441 4.01462 9.55587 4.63974 9.26889C5.73524 8.76597 6.25807 7.50376 5.83905 6.3735C5.59995 5.72855 6.22806 5.10044 6.87301 5.33954C8.00327 5.75856 9.26548 5.23573 9.7684 4.14023Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'DiscountCondition'
};
</script>
