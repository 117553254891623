<template>
  <svg width="100%" height="100%" viewBox="0 0 64 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 4C0 1.79086 1.79086 0 4 0H60C62.2091 0 64 1.79086 64 4V36C64 38.2091 62.2091 40 60 40H4C1.79086 40 0 38.2091 0 36V4Z" fill="#333333"/>
  <rect x="4" y="7" width="26" height="26" rx="3" fill="#474747"/>
  <circle cx="11.5" cy="16" r="2" fill="#D6D6D6"/>
  <path d="M9.69685 24.6453L11.9097 22.0595C11.9846 21.9718 12.0772 21.9008 12.1814 21.8512C12.2856 21.8017 12.3991 21.7746 12.5145 21.7718C12.6299 21.769 12.7446 21.7905 12.8511 21.835C12.9576 21.8795 13.0535 21.9458 13.1326 22.0298L14.1673 23.1271L18.212 18.2946C18.2912 18.1999 18.3908 18.1243 18.5033 18.0733C18.6157 18.0224 18.7383 17.9974 18.8618 18.0002C18.9852 18.0031 19.1065 18.0337 19.2165 18.0898C19.3265 18.1459 19.4224 18.2261 19.4972 18.3243L24.3329 24.6772C24.4254 24.7989 24.482 24.9441 24.4964 25.0962C24.5107 25.2484 24.4823 25.4016 24.4144 25.5385C24.3464 25.6754 24.2415 25.7907 24.1115 25.8713C23.9815 25.952 23.8316 25.9948 23.6786 25.995L10.322 26C10.1649 25.9999 10.0111 25.9549 9.87885 25.8703C9.74656 25.7857 9.6413 25.6649 9.57553 25.5224C9.50976 25.3799 9.48624 25.2216 9.50774 25.0661C9.52924 24.9107 9.59487 24.7646 9.69685 24.6453Z" fill="#D6D6D6"/>
  <rect x="34" y="7" width="26" height="26" rx="3" fill="#474747"/>
  <circle cx="41.5" cy="16" r="2" fill="#D6D6D6"/>
  <path d="M39.6969 24.6453L41.9097 22.0595C41.9846 21.9718 42.0772 21.9008 42.1814 21.8512C42.2856 21.8017 42.3991 21.7746 42.5145 21.7718C42.6299 21.769 42.7446 21.7905 42.8511 21.835C42.9576 21.8795 43.0535 21.9458 43.1326 22.0298L44.1673 23.1271L48.212 18.2946C48.2912 18.1999 48.3908 18.1243 48.5033 18.0733C48.6157 18.0224 48.7383 17.9974 48.8618 18.0002C48.9852 18.0031 49.1065 18.0337 49.2165 18.0898C49.3265 18.1459 49.4224 18.2261 49.4972 18.3243L54.3329 24.6772C54.4254 24.7989 54.482 24.9441 54.4964 25.0962C54.5107 25.2484 54.4823 25.4016 54.4144 25.5385C54.3464 25.6754 54.2415 25.7907 54.1115 25.8713C53.9815 25.952 53.8316 25.9948 53.6786 25.995L40.322 26C40.1649 25.9999 40.0111 25.9549 39.8789 25.8703C39.7466 25.7857 39.6413 25.6649 39.5755 25.5224C39.5098 25.3799 39.4862 25.2216 39.5077 25.0661C39.5292 24.9107 39.5949 24.7646 39.6969 24.6453Z" fill="#D6D6D6"/>
</svg>
</template>

<script>
export default {
  name: 'VariantPresetStyleImage'
};
</script>
