<template>
  <svg width="80" height="48" viewBox="0 0 80 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3709_9112)">
      <path
        d="M0 6C0 2.68629 2.68629 0 6 0H74C77.3137 0 80 2.68629 80 6V42C80 45.3137 77.3137 48 74 48H6C2.68629 48 0 45.3137 0 42V6Z"
        fill="#424242" />
      <path
        d="M30.8467 13.4263C28.4526 10.1912 23.5733 10.1912 21.1792 13.4263L1.16919 40.4655C-1.73083 44.3843 1.09597 49.9038 6.00296 49.9038H20.9071C18.4077 53.7935 21.2113 59 25.9771 59H65.997C70.904 59 73.7308 53.4805 70.8308 49.5617L50.8208 22.5225C48.4267 19.2874 43.5474 19.2874 41.1533 22.5225L39.3658 24.938L30.8467 13.4263Z"
        fill="#4C4C4C" />
      <rect x="28" y="19" width="24" height="4" rx="1.8125" fill="#F9F9F9" />
      <rect x="34" y="25" width="12" height="4" rx="1.8125" fill="#F9F9F9" />
    </g>
    <defs>
      <clipPath id="clip0_3709_9112">
        <path
          d="M0 6C0 2.68629 2.68629 0 6 0H74C77.3137 0 80 2.68629 80 6V42C80 45.3137 77.3137 48 74 48H6C2.68629 48 0 45.3137 0 42V6Z"
          fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'HeroBannerCenterBottomActive',
};
</script>
