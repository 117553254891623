<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 4.5C6.94772 4.5 6.5 4.94772 6.5 5.5V6C6.5 6.55228 6.94772 7 7.5 7H8C8.55228 7 9 6.55228 9 6V5.5C9 4.94772 8.55228 4.5 8 4.5H7.5Z"
      fill="#F9F9F9" />
    <path
      d="M7.5 8.75C6.94772 8.75 6.5 9.19772 6.5 9.75V10.25C6.5 10.8023 6.94772 11.25 7.5 11.25H8C8.55228 11.25 9 10.8023 9 10.25V9.75C9 9.19772 8.55228 8.75 8 8.75H7.5Z"
      fill="#F9F9F9" />
    <path
      d="M6.5 14C6.5 13.4477 6.94772 13 7.5 13H8C8.55228 13 9 13.4477 9 14V14.5C9 15.0523 8.55228 15.5 8 15.5H7.5C6.94772 15.5 6.5 15.0523 6.5 14.5V14Z"
      fill="#F9F9F9" />
    <path
      d="M12 4.5C11.4477 4.5 11 4.94772 11 5.5V6C11 6.55228 11.4477 7 12 7H12.5C13.0523 7 13.5 6.55228 13.5 6V5.5C13.5 4.94772 13.0523 4.5 12.5 4.5H12Z"
      fill="#F9F9F9" />
    <path
      d="M11 9.75C11 9.19772 11.4477 8.75 12 8.75H12.5C13.0523 8.75 13.5 9.19772 13.5 9.75V10.25C13.5 10.8023 13.0523 11.25 12.5 11.25H12C11.4477 11.25 11 10.8023 11 10.25V9.75Z"
      fill="#F9F9F9" />
    <path
      d="M12 13C11.4477 13 11 13.4477 11 14V14.5C11 15.0523 11.4477 15.5 12 15.5H12.5C13.0523 15.5 13.5 15.0523 13.5 14.5V14C13.5 13.4477 13.0523 13 12.5 13H12Z"
      fill="#F9F9F9" />
  </svg>
</template>
