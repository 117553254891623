<template>
  <svg width="100%" height="100%" viewBox="0 0 58 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="1" width="56" height="12" rx="2" fill="#5E5E5E"/>
  <rect x="1" y="15" width="27" height="12" rx="2" fill="#5E5E5E"/>
  <rect x="30" y="15" width="27" height="12" rx="2" fill="#5E5E5E"/>
</svg>
</template>

<script>
export default {
  name: 'LayoutOneCol'
};
</script>
