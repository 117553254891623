<template>
  <svg width="100%" height="100%" viewBox="0 0 60 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V16C28 18.2091 26.2091 20 24 20H4C1.79086 20 0 18.2091 0 16V4Z" fill="#4A4A4A"/>
<path d="M8 5.41281L11.996 4.023C11.996 4.023 12.4819 5.15631 14.004 5.15631C15.5261 5.15631 16.0066 4 16.0066 4L20 5.41281L19.3353 8.89778H17.3313V15.2H10.66V8.89569H8.666L8 5.41281Z" fill="#D6D6D6"/>
<path d="M32 4C32 1.79086 33.7909 0 36 0H56C58.2091 0 60 1.79086 60 4V16C60 18.2091 58.2091 20 56 20H36C33.7909 20 32 18.2091 32 16V4Z" fill="#4A4A4A"/>
<path d="M40 5.41281L43.996 4.023C43.996 4.023 44.4819 5.15631 46.004 5.15631C47.5261 5.15631 48.0066 4 48.0066 4L52 5.41281L51.3353 8.89778H49.3313V15.2H42.66V8.89569H40.666L40 5.41281Z" fill="#D6D6D6"/>
</svg>
</template>

<script>
export default {
  name: 'VariantPresetStyleImageShopifySelected'
};
</script>
