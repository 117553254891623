<template>
  <svg width="100%" height="100%" viewBox="0 0 64 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_12065_63854)">
    <path d="M0 4C0 1.79086 1.79086 0 4 0H60C62.2091 0 64 1.79086 64 4V36C64 38.2091 62.2091 40 60 40H4C1.79086 40 0 38.2091 0 36V4Z" fill="#333333"/>
    <path d="M4 10C4 7.79086 5.79086 6 8 6H40C42.2091 6 44 7.79086 44 10V30C44 32.2091 42.2091 34 40 34H8C5.79086 34 4 32.2091 4 30V10Z" fill="#424242"/>
    <path d="M8 20C8 18.3431 9.34315 17 11 17H37C38.6569 17 40 18.3431 40 20C40 21.6569 38.6569 23 37 23H11C9.34315 23 8 21.6569 8 20Z" fill="#D6D6D6"/>
    <path d="M48 10C48 7.79086 49.7909 6 52 6H84C86.2091 6 88 7.79086 88 10V30C88 32.2091 86.2091 34 84 34H52C49.7909 34 48 32.2091 48 30V10Z" fill="#424242"/>
    <path d="M52 20C52 18.3431 53.3431 17 55 17H81C82.6569 17 84 18.3431 84 20C84 21.6569 82.6569 23 81 23H55C53.3431 23 52 21.6569 52 20Z" fill="#5E5E5E"/>
  </g>
  <defs>
    <clipPath id="clip0_12065_63854">
      <path d="M0 4C0 1.79086 1.79086 0 4 0H60C62.2091 0 64 1.79086 64 4V36C64 38.2091 62.2091 40 60 40H4C1.79086 40 0 38.2091 0 36V4Z" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>

<script>
export default {
  name: 'VariantPresetStyleList'
};
</script>
