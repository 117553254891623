<template>
  <svg width="100%" height="100%" viewBox="0 0 76 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="74" height="46" rx="2" stroke="#5E5E5E" stroke-width="2" />
    <path d="M0 37H76V45C76 46.6569 74.6569 48 73 48H3C1.34315 48 0 46.6569 0 45V37Z" fill="#5E5E5E" />
    <path
      d="M4 42.5C4 41.6716 4.67157 41 5.5 41H30.5C31.3284 41 32 41.6716 32 42.5C32 43.3284 31.3284 44 30.5 44H5.5C4.67157 44 4 43.3284 4 42.5Z"
      fill="#F9F9F9" />
    <path
      d="M34 42.5C34 41.6716 34.6716 41 35.5 41H70.5C71.3284 41 72 41.6716 72 42.5C72 43.3284 71.3284 44 70.5 44H35.5C34.6716 44 34 43.3284 34 42.5Z"
      fill="#F9F9F9" />
  </svg>
</template>
