<template>
  <svg width="51" height="20" viewBox="0 0 51 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_16646_10473)">
      <circle cx="6.5" cy="6" r="5.5" stroke="#F9F9F9" />
      <circle cx="6.5" cy="6" r="3.5" fill="#F9F9F9" />
      <rect x="14.5" width="36" height="4.025" rx="2" fill="#5E5E5E" />
      <rect x="14.5" y="5.32495" width="36" height="4.025" rx="2" fill="#5E5E5E" />
      <rect x="14.5" y="10.6499" width="36" height="4.025" rx="2" fill="#5E5E5E" />
      <rect x="14.5" y="15.9751" width="24" height="4.025" rx="2" fill="#5E5E5E" />
    </g>
    <defs>
      <clipPath id="clip0_16646_10473">
        <rect width="50" height="20" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>
