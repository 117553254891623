<template>
  <svg width="100%" height="100%" viewBox="0 0 80 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 8C8 5.79086 9.79086 4 12 4H68C70.2091 4 72 5.79086 72 8V40C72 42.2091 70.2091 44 68 44H12C9.79086 44 8 42.2091 8 40V8Z" fill="#5E5E5E"/>
<ellipse cx="31.9333" cy="18.6667" rx="2.93333" ry="2.66674" fill="#F9F9F9"/>
<path d="M29.2887 30.1946L32.5342 26.7468C32.6441 26.6298 32.7799 26.5352 32.9327 26.4691C33.0856 26.403 33.2521 26.3669 33.4213 26.3632C33.5905 26.3595 33.7587 26.3882 33.915 26.4475C34.0712 26.5067 34.2118 26.5952 34.3278 26.7072L35.8454 28.1704L41.7777 21.7268C41.8938 21.6006 42.0398 21.4997 42.2048 21.4318C42.3697 21.3638 42.5495 21.3305 42.7306 21.3343C42.9117 21.3381 43.0895 21.3789 43.2509 21.4537C43.4122 21.5285 43.5529 21.6354 43.6625 21.7664L50.7549 30.2371C50.8905 30.3995 50.9735 30.593 50.9947 30.7959C51.0158 30.9988 50.9741 31.203 50.8744 31.3856C50.7747 31.5682 50.6209 31.7218 50.4302 31.8294C50.2396 31.9369 50.0197 31.994 49.7952 31.9942L30.2057 32.0009C29.9753 32.0009 29.7497 31.9408 29.5557 31.828C29.3616 31.7151 29.2072 31.5542 29.1108 31.3642C29.0143 31.1741 28.9798 30.963 29.0113 30.7557C29.0429 30.5485 29.1391 30.3537 29.2887 30.1946Z" fill="#F9F9F9"/>
</svg>
</template>

<script>
export default {
  name: 'ProductBadgeImage'
};
</script>
