<template>
  <svg width="100%" height="100%" viewBox="0 0 64 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="63" height="31" stroke="#5E5E5E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.80005 15.9999C7.80005 15.6685 8.06868 15.3999 8.40005 15.3999H13.6C13.9314 15.3999 14.2 15.6685 14.2 15.9999C14.2 16.3313 13.9314 16.5999 13.6 16.5999H8.40005C8.06868 16.5999 7.80005 16.3313 7.80005 15.9999Z" fill="#F9F9F9"/>
<rect x="21" width="1" height="32" fill="#5E5E5E"/>
<path d="M33.2051 12V20H31.9941V13.2109H31.9473L30.0332 14.4609V13.3047L32.0293 12H33.2051Z" fill="white"/>
<rect x="42" width="1" height="32" fill="#5E5E5E"/>
<path d="M53.6 13.4C53.6 13.0687 53.3314 12.8 53 12.8C52.6687 12.8 52.4 13.0687 52.4 13.4L52.4 15.4H50.4C50.0687 15.4 49.8 15.6687 49.8 16C49.8 16.3314 50.0687 16.6 50.4 16.6H52.4L52.4 18.6C52.4 18.9314 52.6687 19.2 53 19.2C53.3314 19.2 53.6 18.9314 53.6 18.6V16.6H55.6C55.9314 16.6 56.2 16.3314 56.2 16C56.2 15.6687 55.9314 15.4 55.6 15.4H53.6V13.4Z" fill="#F9F9F9"/>
</svg>
</template>

<script>
export default {
  name: 'ProductQuantitySquareDivider'
};
</script>
