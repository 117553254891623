<template>
  <svg width="100%" height="100%" viewBox="0 0 54 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="12" height="12" rx="3" fill="#5E5E5E"/>
<rect y="14" width="12" height="12" rx="3" fill="#5E5E5E"/>
<rect y="28" width="12" height="12" rx="3" fill="#5E5E5E"/>
<path d="M14 3C14 1.34315 15.3431 0 17 0L51 0C52.6569 0 54 1.34315 54 3L54 41C54 42.6569 52.6569 44 51 44L17 44C15.3431 44 14 42.6569 14 41L14 3Z" fill="#5E5E5E"/>
<path d="M25 15.7188L30.994 13.6341C30.994 13.6341 31.7228 15.3341 34.006 15.3341C36.2891 15.3341 37.01 13.5996 37.01 13.5996L43 15.7188L42.003 20.9463L38.997 20.9463L38.997 30.3996L28.99 30.3996L28.99 20.9431H25.999L25 15.7188Z" fill="#F9F9F9"/>
</svg>
</template>

<script>
export default {
  name: 'ProductImageLayoutLeft'
};
</script>
