<template>
  <svg width="100%" height="100%" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 3C0 1.34315 1.34315 0 3 0L47 0C48.6569 0 50 1.34315 50 3L50 47C50 48.6569 48.6569 50 47 50L3 50C1.34315 50 0 48.6569 0 47L0 3Z" fill="#5E5E5E"/>
<path d="M16 18.7188L21.994 16.6341C21.994 16.6341 22.7228 18.3341 25.006 18.3341C27.2891 18.3341 28.01 16.5996 28.01 16.5996L34 18.7188L33.003 23.9463L29.997 23.9463L29.997 33.3996H19.99L19.99 23.9431H16.999L16 18.7188Z" fill="#F9F9F9"/>
</svg>
</template>

<script>
export default {
  name: 'ProductImageLayoutFeaturedOnly'
};
</script>
