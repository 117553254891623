<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.375 2C7.375 1.65482 7.65482 1.375 8 1.375H12C12.3452 1.375 12.625 1.65482 12.625 2V6C12.625 6.34518 12.3452 6.625 12 6.625H8C7.65482 6.625 7.375 6.34518 7.375 6V2ZM8.625 2.625V5.375H11.375V2.625H8.625Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.375 8C7.375 7.65482 7.65482 7.375 8 7.375H12C12.3452 7.375 12.625 7.65482 12.625 8V12C12.625 12.3452 12.3452 12.625 12 12.625H8C7.65482 12.625 7.375 12.3452 7.375 12V8ZM8.625 8.625V11.375H11.375V8.625H8.625Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 13.375C7.65482 13.375 7.375 13.6548 7.375 14V18C7.375 18.3452 7.65482 18.625 8 18.625H12C12.3452 18.625 12.625 18.3452 12.625 18V14C12.625 13.6548 12.3452 13.375 12 13.375H8ZM8.625 17.375V14.625H11.375V17.375H8.625Z"
      fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'BgRepeatY',
};
</script>
