<script setup lang="ts">
import type { RadioProps } from './types';

import { defineProps, defineEmits } from 'vue';
import { useRadioTone } from './hooks/useRadioTone';
import { useRadioSize } from './hooks/useRadioSize';
import { useRadioSelection } from './hooks/useRadioSelection';
import { useDisabled } from './hooks/useRadioDisabled';

const props = defineProps<RadioProps>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number): void;
  (e: 'onChange', controlId?: string | number, value?: string | number): void;
  (e: 'onFocus', controlId?: string | number, value?: string | number): void;
  (e: 'onBlur', controlId?: string | number, value?: string | number): void;
}>();

// Hooks
const { containerCls } = useRadioTone({ props });
const { radioSizeCls, radioHoverSizeCls, radioActiveSizeCls } = useRadioSize({ props });
const { radioActiveCls, radioInactiveCls, radioHoverCls } = useRadioSelection({ props });
const { isDisabled, containerDisableCls } = useDisabled(props.disabled || false);

// Event Handlers
const handleChange = (value: string | number) => {
  if (isDisabled.value) return;

  emit('update:modelValue', value);
  emit('onChange', props.controlId, value);
};

const handleFocus = () => {
  if (isDisabled.value) return;

  emit('onFocus', props.controlId, props.modelValue);
};

const handleBlur = () => {
  if (isDisabled.value) return;

  emit('onBlur', props.controlId, props.modelValue);
};
</script>

<template>
  <div
    class="group/radio text-14 rounded-medium flex select-none items-start gap-8 text-center"
    :class="[...containerCls, ...containerDisableCls, containerClasses]"
    @click.stop="handleChange(props.option.value)"
    @focus="handleFocus"
    @blur="handleBlur">
    <div class="relative p-[2px]">
      <div
        class="flex items-center justify-center rounded-full border-[2px] leading-6"
        :class="[...radioSizeCls, ...radioActiveCls, ...radioInactiveCls, ...radioActiveSizeCls]" />
      <div
        class="pointer-events-none absolute rounded-full transition-all"
        :class="[...radioHoverCls, ...radioHoverSizeCls]" />
    </div>

    <div class="flex flex-col flex-nowrap justify-start text-left">
      <p class="text-14 leading-6" :class="props.boldLabel ? 'font-semibold' : 'font-regular'">
        {{ props.option.label }}
      </p>
      <p class="text-12 font-regular leading-6">{{ props.option.helpText }}</p>
    </div>
  </div>
</template>
