<template>
  <svg width="100%" height="100%" viewBox="0 0 76 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="74" height="46" rx="2" stroke="#5E5E5E" stroke-width="2" />
    <path d="M0 3C0 1.34315 1.34315 0 3 0H73C74.6569 0 76 1.34315 76 3V11H0V3Z" fill="#5E5E5E" />
    <path
      d="M4 5.5C4 4.67157 4.67157 4 5.5 4H30.5C31.3284 4 32 4.67157 32 5.5C32 6.32843 31.3284 7 30.5 7H5.5C4.67157 7 4 6.32843 4 5.5Z"
      fill="#F9F9F9" />
    <path
      d="M34 5.5C34 4.67157 34.6716 4 35.5 4H70.5C71.3284 4 72 4.67157 72 5.5C72 6.32843 71.3284 7 70.5 7H35.5C34.6716 7 34 6.32843 34 5.5Z"
      fill="#F9F9F9" />
  </svg>
</template>
