<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.52148 14.75C2.52148 15.7165 3.20054 16.5 4.03819 16.5H10.5048C11.3424 16.5 12.0215 15.7165 12.0215 14.75V5.25C12.0215 4.2835 11.3424 3.5 10.5048 3.5H4.03819C3.20054 3.5 2.52148 4.2835 2.52148 5.25V14.75ZM4.03819 15C3.91852 15 3.82152 14.8881 3.82152 14.75V5.25C3.82152 5.11193 3.91852 5 4.03819 5H10.5048C10.6244 5 10.7215 5.11193 10.7215 5.25V14.75C10.7215 14.8881 10.6244 15 10.5048 15H4.03819ZM14 5.25C14 4.2835 15.0074 3.5 16.25 3.5H17.5357C18.0683 3.5 18.5 3.83579 18.5 4.25C18.5 4.66421 18.0683 5 17.5357 5H16.25C16.0725 5 15.9286 5.11193 15.9286 5.25V6.25C15.9286 6.66421 15.4968 7 14.9643 7C14.4317 7 14 6.66421 14 6.25V5.25ZM16.25 16.5C15.0074 16.5 14 15.7165 14 14.75V13.75C14 13.3358 14.4317 13 14.9643 13C15.4968 13 15.9286 13.3358 15.9286 13.75V14.75C15.9286 14.8881 16.0725 15 16.25 15H17.5357C18.0683 15 18.5 15.3358 18.5 15.75C18.5 16.1642 18.0683 16.5 17.5357 16.5H16.25ZM15.9286 9C15.9286 8.58579 15.4968 8.25 14.9643 8.25C14.4317 8.25 14 8.58579 14 9V11C14 11.4142 14.4317 11.75 14.9643 11.75C15.4968 11.75 15.9286 11.4142 15.9286 11V9Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'SneakPeekLeft'
};
</script>
