import gpAccordionHeaderLeftIconIcon from './svgs/AccordionHeaderLeftIcon.vue';
import gpAccordionHeaderNoIconIcon from './svgs/AccordionHeaderNoIcon.vue';
import gpAccordionHeaderRightIconIcon from './svgs/AccordionHeaderRightIcon.vue';
import gpAlignPositionCenterIcon from './svgs/AlignPositionCenter.vue';
import gpAlignPositionTopIcon from './svgs/AlignPositionTop.vue';
import gpArrangeHorizontalIcon from './svgs/ArrangeHorizontal.vue';
import gpArrangeVerticalIcon from './svgs/ArrangeVertical.vue';
import gpArticleFilledSquareIcon from './svgs/ArticleFilledSquare.vue';
import gpArticleLinkIcon from './svgs/ArticleLink.vue';
import gpArticleOutlineIcon from './svgs/ArticleOutline.vue';
import gpArticleRoundedIcon from './svgs/ArticleRounded.vue';
import gpBundleHorizontalBaseLineLayoutIcon from './svgs/BundleHorizontalBaseLineLayout.vue';
import gpBundleHorizontalCenterLayoutIcon from './svgs/BundleHorizontalCenterLayout.vue';
import gpBundleVerticalBaselineLayoutIcon from './svgs/BundleVerticalBaselineLayout.vue';
import gpBundleVerticalCenterLayoutIcon from './svgs/BundleVerticalCenterLayout.vue';
import gpCarouselDotInsideIcon from './svgs/CarouselDotInside.vue';
import gpCarouselDotOutsideIcon from './svgs/CarouselDotOutside.vue';
import gpCheckboxIcon from './svgs/Checkbox.vue';
import gpCheckmarkIcon from './svgs/Checkmark.vue';
import gpCouponCodeLayoutIcon from './svgs/CouponCodeLayout.vue';
import gpCouponCodeNoIconLayoutIcon from './svgs/CouponCodeNoIconLayout.vue';
import gpGridLayoutIcon from './svgs/GridLayout.vue';
import gpHeroBannerBottomCenterIcon from './svgs/HeroBannerBottomCenter.vue';
import gpHeroBannerBottomLeftActiveIcon from './svgs/HeroBannerBottomLeftActive.vue';
import gpHeroBannerCenterIcon from './svgs/HeroBannerCenter.vue';
import gpHeroBannerCenterActiveIcon from './svgs/HeroBannerCenterActive.vue';
import gpHeroBannerCenterBottomActiveIcon from './svgs/HeroBannerCenterBottomActive.vue';
import gpHeroBannerCenterTopActiveIcon from './svgs/HeroBannerCenterTopActive.vue';
import gpHeroBannerLeftIcon from './svgs/HeroBannerLeft.vue';
import gpHeroBannerLeftActiveIcon from './svgs/HeroBannerLeftActive.vue';
import gpHeroBannerLeftBottomIcon from './svgs/HeroBannerLeftBottom.vue';
import gpHeroBannerLeftTopIcon from './svgs/HeroBannerLeftTop.vue';
import gpHeroBannerRightIcon from './svgs/HeroBannerRight.vue';
import gpHeroBannerRightActiveIcon from './svgs/HeroBannerRightActive.vue';
import gpHeroBannerRightBottomIcon from './svgs/HeroBannerRightBottom.vue';
import gpHeroBannerRightBottomActiveIcon from './svgs/HeroBannerRightBottomActive.vue';
import gpHeroBannerRightTopIcon from './svgs/HeroBannerRightTop.vue';
import gpHeroBannerRightTopActiveIcon from './svgs/HeroBannerRightTopActive.vue';
import gpHeroBannerTopCenterIcon from './svgs/HeroBannerTopCenter.vue';
import gpHeroBannerTopLeftActiveIcon from './svgs/HeroBannerTopLeftActive.vue';
import gpImageCircleStyleIcon from './svgs/ImageCircleStyle.vue';
import gpImageComparisonHorizontalIcon from './svgs/ImageComparisonHorizontal.vue';
import gpImageComparisonVerticalIcon from './svgs/ImageComparisonVertical.vue';
import gpImageContainIcon from './svgs/ImageContain.vue';
import gpImageCoverIcon from './svgs/ImageCover.vue';
import gpImageRectangleStyleIcon from './svgs/ImageRectangleStyle.vue';
import gpInfoIcon from './svgs/Info.vue';
import gpInputCheckboxIcon from './svgs/InputCheckbox.vue';
import gpInputDropdownIcon from './svgs/InputDropdown.vue';
import gpInputEmailIcon from './svgs/InputEmail.vue';
import gpInputHiddenFieldIcon from './svgs/InputHiddenField.vue';
import gpInputRadioButtonIcon from './svgs/InputRadioButton.vue';
import gpInputTextIcon from './svgs/InputText.vue';
import gpInputTextAreaIcon from './svgs/InputTextArea.vue';
import gpLayoutOneColIcon from './svgs/LayoutOneCol.vue';
import gpLayoutTwoColIcon from './svgs/LayoutTwoCol.vue';
import gpLinkButtonIcon from './svgs/LinkButton.vue';
import gpNoResultIcon from './svgs/NoResult.vue';
import gpPostPurchaseBlackSquareIcon from './svgs/PostPurchaseBlackSquare.vue';
import gpPostPurchaseGreenSquareIcon from './svgs/PostPurchaseGreenSquare.vue';
import gpPostPurchaseRedSquareIcon from './svgs/PostPurchaseRedSquare.vue';
import gpPostPurchaseYellowSquareIcon from './svgs/PostPurchaseYellowSquare.vue';
import gpPrefixIcon from './svgs/Prefix.vue';
import gpProductBadgeHorizontalRectangleIcon from './svgs/ProductBadgeHorizontalRectangle.vue';
import gpProductBadgeHozionReactangleIcon from './svgs/ProductBadgeHozionReactangle.vue';
import gpProductBadgeImageIcon from './svgs/ProductBadgeImage.vue';
import gpProductBadgeRibbonIcon from './svgs/ProductBadgeRibbon.vue';
import gpProductBadgeRibbon2Icon from './svgs/ProductBadgeRibbon2.vue';
import gpProductBadgeRibbon3Icon from './svgs/ProductBadgeRibbon3.vue';
import gpProductBadgeSquareIcon from './svgs/ProductBadgeSquare.vue';
import gpProductBadgeStarIcon from './svgs/ProductBadgeStar.vue';
import gpProductBadgeTextIcon from './svgs/ProductBadgeText.vue';
import gpProductBadgeTriangleIcon from './svgs/ProductBadgeTriangle.vue';
import gpProductBadgeVerticalRectangleIcon from './svgs/ProductBadgeVerticalRectangle.vue';
import gpProductButtonDotSeparatorIcon from './svgs/ProductButtonDotSeparator.vue';
import gpProductButtonHorizontalLineSeparatorIcon from './svgs/ProductButtonHorizontalLineSeparator.vue';
import gpProductButtonVerticalLineSeparatorIcon from './svgs/ProductButtonVerticalLineSeparator.vue';
import gpProductButtonPriceOnlyLayout from './svgs/ProductButtonPriceOnlyLayout.vue';
import gpProductButtonPriceBeforeLayout from './svgs/ProductButtonPriceBeforeLayout.vue';
import gpProductButtonPriceAfterLayout from './svgs/ProductButtonPriceAfterLayout.vue';
import gpProductImageLayoutBottomIcon from './svgs/ProductImageLayoutBottom.vue';
import gpProductImageLayoutBottomInsideIcon from './svgs/ProductImageLayoutBottomInside.vue';
import gpProductImageLayoutFeaturedOnlyIcon from './svgs/ProductImageLayoutFeaturedOnly.vue';
import gpProductImageLayoutLeftIcon from './svgs/ProductImageLayoutLeft.vue';
import gpProductImageLayoutLeftInsideIcon from './svgs/ProductImageLayoutLeftInside.vue';
import gpProductImageLayoutOneColIcon from './svgs/ProductImageLayoutOneCol.vue';
import gpProductImageLayoutRightIcon from './svgs/ProductImageLayoutRight.vue';
import gpProductImageLayoutRightInsideIcon from './svgs/ProductImageLayoutRightInside.vue';
import gpProductImageLayoutTwoColIcon from './svgs/ProductImageLayoutTwoCol.vue';
import gpProductImagePlaceholderIcon from './svgs/ProductImagePlaceholder.vue';
import gpProductLayoutHorizontalIcon from './svgs/ProductLayoutHorizontal.vue';
import gpProductLayoutVerticalIcon from './svgs/ProductLayoutVertical.vue';
import gpProductQuantityRoundedIcon from './svgs/ProductQuantityRounded.vue';
import gpProductQuantityRoundedDividerIcon from './svgs/ProductQuantityRoundedDivider.vue';
import gpProductQuantityRoundedInputOnlyIcon from './svgs/ProductQuantityRoundedInputOnly.vue';
import gpProductQuantitySquareIcon from './svgs/ProductQuantitySquare.vue';
import gpProductQuantitySquareDividerIcon from './svgs/ProductQuantitySquareDivider.vue';
import gpProductQuantitySquareInputOnlyIcon from './svgs/ProductQuantitySquareInputOnly.vue';
import gpSecondaryButtonIcon from './svgs/SecondaryButton.vue';
import gpSlideControlInsideIcon from './svgs/SlideControlInside.vue';
import gpSlideControlOutsideIcon from './svgs/SlideControlOutside.vue';
import gpSliderLayoutIcon from './svgs/SliderLayout.vue';
import gpStatusErrorFilledIcon from './svgs/StatusErrorFilled.vue';
import gpStickyPositionBottomIcon from './svgs/StickyPositionBottom.vue';
import gpStickyPositionTopIcon from './svgs/StickyPositionTop.vue';
import gpStockCounterFullIcon from './svgs/StockCounterFull.vue';
import gpStockCounterIconAndTextIcon from './svgs/StockCounterIconAndText.vue';
import gpStockCounterProgressBarIcon from './svgs/StockCounterProgressBar.vue';
import gpStockCounterTextAndProgressBarIcon from './svgs/StockCounterTextAndProgressBar.vue';
import gpStockCounterTextOnlyIcon from './svgs/StockCounterTextOnly.vue';
import gpSyncProductOffIcon from './svgs/SyncProductOff.vue';
import gpSyncProductOnIcon from './svgs/SyncProductOn.vue';
import gpTabBottomIcon from './svgs/TabBottom.vue';
import gpTabLeftIcon from './svgs/TabLeft.vue';
import gpTabTopIcon from './svgs/TabTop.vue';
import gpVariantPresetStyleColorIcon from './svgs/VariantPresetStyleColor.vue';
import gpVariantPresetStyleColorSelectedIcon from './svgs/VariantPresetStyleColorSelected.vue';
import gpVariantPresetStyleDropdownIcon from './svgs/VariantPresetStyleDropdown.vue';
import gpVariantPresetStyleDropdownSelectedIcon from './svgs/VariantPresetStyleDropdownSelected.vue';
import gpVariantPresetStyleImageIcon from './svgs/VariantPresetStyleImage.vue';
import gpVariantPresetStyleImageSelectedIcon from './svgs/VariantPresetStyleImageSelected.vue';
import gpVariantPresetStyleImageShopifyIcon from './svgs/VariantPresetStyleImageShopify.vue';
import gpVariantPresetStyleImageShopifySelectedIcon from './svgs/VariantPresetStyleImageShopifySelected.vue';
import gpVariantPresetStyleListIcon from './svgs/VariantPresetStyleList.vue';
import gpVariantPresetStyleListSelectedIcon from './svgs/VariantPresetStyleListSelected.vue';
import gpGpHeroBannerCenterBottomActiveIconIcon from './svgs/gpHeroBannerCenterBottomActiveIcon.vue';
import gpGpHeroBannerCenterTopActiveIconIcon from './svgs/gpHeroBannerCenterTopActiveIcon.vue';

export const icons = {
  'gp-accordion-header-left-icon': gpAccordionHeaderLeftIconIcon,
  'gp-accordion-header-no-icon': gpAccordionHeaderNoIconIcon,
  'gp-accordion-header-right-icon': gpAccordionHeaderRightIconIcon,
  'gp-align-position-center': gpAlignPositionCenterIcon,
  'gp-align-position-top': gpAlignPositionTopIcon,
  'gp-arrange-horizontal': gpArrangeHorizontalIcon,
  'gp-arrange-vertical': gpArrangeVerticalIcon,
  'gp-article-filled-square': gpArticleFilledSquareIcon,
  'gp-article-link': gpArticleLinkIcon,
  'gp-article-outline': gpArticleOutlineIcon,
  'gp-article-rounded': gpArticleRoundedIcon,
  'gp-bundle-horizontal-base-line-layout': gpBundleHorizontalBaseLineLayoutIcon,
  'gp-bundle-horizontal-center-layout': gpBundleHorizontalCenterLayoutIcon,
  'gp-bundle-vertical-baseline-layout': gpBundleVerticalBaselineLayoutIcon,
  'gp-bundle-vertical-center-layout': gpBundleVerticalCenterLayoutIcon,
  'gp-carousel-dot-inside': gpCarouselDotInsideIcon,
  'gp-carousel-dot-outside': gpCarouselDotOutsideIcon,
  'gp-checkbox': gpCheckboxIcon,
  'gp-checkmark': gpCheckmarkIcon,
  'gp-coupon-code-layout': gpCouponCodeLayoutIcon,
  'gp-coupon-code-no-icon-layout': gpCouponCodeNoIconLayoutIcon,
  'gp-grid-layout': gpGridLayoutIcon,
  'gp-hero-banner-bottom-center': gpHeroBannerBottomCenterIcon,
  'gp-hero-banner-bottom-left-active': gpHeroBannerBottomLeftActiveIcon,
  'gp-hero-banner-center': gpHeroBannerCenterIcon,
  'gp-hero-banner-center-active': gpHeroBannerCenterActiveIcon,
  'gp-hero-banner-center-bottom-active': gpHeroBannerCenterBottomActiveIcon,
  'gp-hero-banner-center-top-active': gpHeroBannerCenterTopActiveIcon,
  'gp-hero-banner-left': gpHeroBannerLeftIcon,
  'gp-hero-banner-left-active': gpHeroBannerLeftActiveIcon,
  'gp-hero-banner-left-bottom': gpHeroBannerLeftBottomIcon,
  'gp-hero-banner-left-top': gpHeroBannerLeftTopIcon,
  'gp-hero-banner-right': gpHeroBannerRightIcon,
  'gp-hero-banner-right-active': gpHeroBannerRightActiveIcon,
  'gp-hero-banner-right-bottom': gpHeroBannerRightBottomIcon,
  'gp-hero-banner-right-bottom-active': gpHeroBannerRightBottomActiveIcon,
  'gp-hero-banner-right-top': gpHeroBannerRightTopIcon,
  'gp-hero-banner-right-top-active': gpHeroBannerRightTopActiveIcon,
  'gp-hero-banner-top-center': gpHeroBannerTopCenterIcon,
  'gp-hero-banner-top-left-active': gpHeroBannerTopLeftActiveIcon,
  'gp-image-circle-style': gpImageCircleStyleIcon,
  'gp-image-comparison-horizontal': gpImageComparisonHorizontalIcon,
  'gp-image-comparison-vertical': gpImageComparisonVerticalIcon,
  'gp-image-contain': gpImageContainIcon,
  'gp-image-cover': gpImageCoverIcon,
  'gp-image-rectangle-style': gpImageRectangleStyleIcon,
  'gp-info': gpInfoIcon,
  'gp-input-checkbox': gpInputCheckboxIcon,
  'gp-input-dropdown': gpInputDropdownIcon,
  'gp-input-email': gpInputEmailIcon,
  'gp-input-hidden-field': gpInputHiddenFieldIcon,
  'gp-input-radio-button': gpInputRadioButtonIcon,
  'gp-input-text': gpInputTextIcon,
  'gp-input-text-area': gpInputTextAreaIcon,
  'gp-layout-one-col': gpLayoutOneColIcon,
  'gp-layout-two-col': gpLayoutTwoColIcon,
  'gp-link-button': gpLinkButtonIcon,
  'gp-no-result': gpNoResultIcon,
  'gp-post-purchase-black-square': gpPostPurchaseBlackSquareIcon,
  'gp-post-purchase-green-square': gpPostPurchaseGreenSquareIcon,
  'gp-post-purchase-red-square': gpPostPurchaseRedSquareIcon,
  'gp-post-purchase-yellow-square': gpPostPurchaseYellowSquareIcon,
  'gp-prefix': gpPrefixIcon,
  'gp-product-badge-horizontal-rectangle': gpProductBadgeHorizontalRectangleIcon,
  'gp-product-badge-hozion-reactangle': gpProductBadgeHozionReactangleIcon,
  'gp-product-badge-image': gpProductBadgeImageIcon,
  'gp-product-badge-ribbon': gpProductBadgeRibbonIcon,
  'gp-product-badge-ribbon-2': gpProductBadgeRibbon2Icon,
  'gp-product-badge-ribbon-3': gpProductBadgeRibbon3Icon,
  'gp-product-badge-square': gpProductBadgeSquareIcon,
  'gp-product-badge-star': gpProductBadgeStarIcon,
  'gp-product-badge-text': gpProductBadgeTextIcon,
  'gp-product-badge-triangle': gpProductBadgeTriangleIcon,
  'gp-product-badge-vertical-rectangle': gpProductBadgeVerticalRectangleIcon,
  'gp-product-button-dot-separator': gpProductButtonDotSeparatorIcon,
  'gp-product-button-horizontal-line-separator': gpProductButtonHorizontalLineSeparatorIcon,
  'gp-product-button-vertical-line-separator': gpProductButtonVerticalLineSeparatorIcon,
  'gp-product-button-price-only-layout': gpProductButtonPriceOnlyLayout,
  'gp-product-button-price-before-layout': gpProductButtonPriceBeforeLayout,
  'gp-product-button-price-after-layout': gpProductButtonPriceAfterLayout,
  'gp-product-image-layout-bottom': gpProductImageLayoutBottomIcon,
  'gp-product-image-layout-bottom-inside': gpProductImageLayoutBottomInsideIcon,
  'gp-product-image-layout-featured-only': gpProductImageLayoutFeaturedOnlyIcon,
  'gp-product-image-layout-left': gpProductImageLayoutLeftIcon,
  'gp-product-image-layout-left-inside': gpProductImageLayoutLeftInsideIcon,
  'gp-product-image-layout-one-col': gpProductImageLayoutOneColIcon,
  'gp-product-image-layout-right': gpProductImageLayoutRightIcon,
  'gp-product-image-layout-right-inside': gpProductImageLayoutRightInsideIcon,
  'gp-product-image-layout-two-col': gpProductImageLayoutTwoColIcon,
  'gp-product-image-placeholder': gpProductImagePlaceholderIcon,
  'gp-product-layout-horizontal': gpProductLayoutHorizontalIcon,
  'gp-product-layout-vertical': gpProductLayoutVerticalIcon,
  'gp-product-quantity-rounded': gpProductQuantityRoundedIcon,
  'gp-product-quantity-rounded-divider': gpProductQuantityRoundedDividerIcon,
  'gp-product-quantity-rounded-input-only': gpProductQuantityRoundedInputOnlyIcon,
  'gp-product-quantity-square': gpProductQuantitySquareIcon,
  'gp-product-quantity-square-divider': gpProductQuantitySquareDividerIcon,
  'gp-product-quantity-square-input-only': gpProductQuantitySquareInputOnlyIcon,
  'gp-secondary-button': gpSecondaryButtonIcon,
  'gp-slide-control-inside': gpSlideControlInsideIcon,
  'gp-slide-control-outside': gpSlideControlOutsideIcon,
  'gp-slider-layout': gpSliderLayoutIcon,
  'gp-status-error-filled': gpStatusErrorFilledIcon,
  'gp-sticky-position-bottom': gpStickyPositionBottomIcon,
  'gp-sticky-position-top': gpStickyPositionTopIcon,
  'gp-stock-counter-full': gpStockCounterFullIcon,
  'gp-stock-counter-icon-and-text': gpStockCounterIconAndTextIcon,
  'gp-stock-counter-progress-bar': gpStockCounterProgressBarIcon,
  'gp-stock-counter-text-and-progress-bar': gpStockCounterTextAndProgressBarIcon,
  'gp-stock-counter-text-only': gpStockCounterTextOnlyIcon,
  'gp-sync-product-off': gpSyncProductOffIcon,
  'gp-sync-product-on': gpSyncProductOnIcon,
  'gp-tab-bottom': gpTabBottomIcon,
  'gp-tab-left': gpTabLeftIcon,
  'gp-tab-top': gpTabTopIcon,
  'gp-variant-preset-style-color': gpVariantPresetStyleColorIcon,
  'gp-variant-preset-style-color-selected': gpVariantPresetStyleColorSelectedIcon,
  'gp-variant-preset-style-dropdown': gpVariantPresetStyleDropdownIcon,
  'gp-variant-preset-style-dropdown-selected': gpVariantPresetStyleDropdownSelectedIcon,
  'gp-variant-preset-style-image': gpVariantPresetStyleImageIcon,
  'gp-variant-preset-style-image-selected': gpVariantPresetStyleImageSelectedIcon,
  'gp-variant-preset-style-image-shopify': gpVariantPresetStyleImageShopifyIcon,
  'gp-variant-preset-style-image-shopify-selected': gpVariantPresetStyleImageShopifySelectedIcon,
  'gp-variant-preset-style-list': gpVariantPresetStyleListIcon,
  'gp-variant-preset-style-list-selected': gpVariantPresetStyleListSelectedIcon,
  'gp-gp-hero-banner-center-bottom-active-icon': gpGpHeroBannerCenterBottomActiveIconIcon,
  'gp-gp-hero-banner-center-top-active-icon': gpGpHeroBannerCenterTopActiveIconIcon,
};
