<template>
  <svg width="100%" height="100%" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 3C0 1.34315 1.34315 0 3 0H47C48.6569 0 50 1.34315 50 3V47C50 48.6569 48.6569 50 47 50H3C1.34315 50 0 48.6569 0 47V3Z" fill="#5E5E5E"/>
<path d="M12 18.7188L17.994 16.6341C17.994 16.6341 18.7228 18.3341 21.006 18.3341C23.2891 18.3341 24.01 16.5996 24.01 16.5996L30 18.7188L29.003 23.9463L25.997 23.9463L25.997 33.3996H15.99L15.99 23.9431H12.999L12 18.7188Z" fill="#F9F9F9"/>
<rect x="34" y="5" width="12" height="12" rx="3" fill="#757575"/>
<rect x="34" y="19" width="12" height="12" rx="3" fill="#757575"/>
<rect x="34" y="33" width="12" height="12" rx="3" fill="#757575"/>
</svg>
</template>

<script>
export default {
  name: 'ProductImageLayoutRightInside'
};
</script>
