<script lang="ts" setup>
import GPopover from '../../base/GPopover.vue';
import { GButtonV2 } from '@gem/uikit';
import { ref } from 'vue';

defineProps<{
  labelHeader: string;
  isShowTips?: boolean;
  isShowIllustration?: boolean;
  tipsType?: 'info' | 'warning';
  classesTips?: string;
  labelViewLive?: string;
  hideViewliveButton?: boolean;
  isLoading?: boolean;
}>();

const emit = defineEmits<{
  (e: 'view-live'): void;
  (e: 'close'): void;
}>();

const handleClose = () => {
  emit('close');
};

const handleViewLive = () => {
  emit('view-live');
};

const popoverRef = ref();

const open = () => {
  popoverRef.value.onOpen();
};

const close = () => {
  popoverRef.value.close();
};

defineExpose({ close, open });
</script>
<template>
  <g-popover
    ref="popoverRef"
    :has-arrow="false"
    :closeable="true"
    overlay-container="#root-modal"
    wrapper-class="w-[360px] !top-[60px] !right-[10px] !left-[unset]"
    cls="p-0"
    @close="emit('close')">
    <template #content>
      <div class="bg-dark-500 rounded-[16px]">
        <div class="border-dark-400 flex items-center border-b border-solid px-16 py-10">
          <p class="text-light-200 text-12 font-medium leading-5">
            {{ labelHeader }}
          </p>
          <span
            class="text-text-dark-300 hover:text-light-450 hover:bg-dark-250 ml-auto cursor-pointer rounded-xl p-[11px] transition-colors duration-200"
            @click="handleClose">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.17554 9.02407C8.40986 9.25838 8.78975 9.25838 9.02407 9.02407C9.25838 8.78975 9.25838 8.40986 9.02407 8.17554L5.84833 4.9998L9.02407 1.82407C9.25838 1.58975 9.25838 1.20986 9.02407 0.975541C8.78975 0.741226 8.40986 0.741226 8.17554 0.975541L4.9998 4.15128L1.82407 0.975541C1.58975 0.741226 1.20986 0.741226 0.975541 0.975541C0.741226 1.20986 0.741226 1.58975 0.975541 1.82407L4.15128 4.9998L0.975541 8.17554C0.741226 8.40986 0.741226 8.78975 0.975541 9.02407C1.20986 9.25838 1.58975 9.25838 1.82407 9.02407L4.9998 5.84833L8.17554 9.02407Z"
                fill="#F9F9F9" />
            </svg>
          </span>
        </div>
        <div v-if="isShowIllustration || isShowTips" class="flex flex-col gap-16 p-16">
          <div v-if="isShowTips" class="tips-container" :class="classesTips">
            <div
              class="flex gap-6 rounded-[16px] p-16"
              :class="{ 'bg-p-text-info-active': tipsType == 'info', 'bg-underlay-yellow-150': tipsType == 'warning' }">
              <span class="">
                <template v-if="tipsType == 'warning'">
                  <div class="flex h-20 w-20 items-center justify-center pt-[2px]">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.99997 3.33984C7.41418 3.33984 7.74997 3.67563 7.74997 4.08984V7.58984C7.74997 8.00406 7.41418 8.33984 6.99997 8.33984C6.58576 8.33984 6.24997 8.00406 6.24997 7.58984V4.08984C6.24997 3.67563 6.58576 3.33984 6.99997 3.33984Z"
                        fill="#FED571" />
                      <path
                        d="M8.00003 10.0898C8.00003 10.6421 7.55232 11.0898 7.00003 11.0898C6.44775 11.0898 6.00003 10.6421 6.00003 10.0898C6.00003 9.53756 6.44775 9.08984 7.00003 9.08984C7.55232 9.08984 8.00003 9.53756 8.00003 10.0898Z"
                        fill="#FED571" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.00007 0.0898438C5.95474 0.0898438 5.21619 0.792167 4.84765 1.53656C4.65568 1.92432 3.75987 3.63395 2.84308 5.38338L2.81534 5.4363C1.92293 7.13919 1.01717 8.86756 0.807189 9.29181C0.435129 10.0435 0.329336 11.0417 0.89956 11.9058C1.46914 12.7689 2.44225 13.0898 3.3636 13.0898L10.6364 13.0898C11.5577 13.0898 12.5309 12.769 13.1005 11.9058C13.6707 11.0417 13.5649 10.0435 13.1928 9.29181C12.9832 8.86823 12.08 7.14483 11.189 5.4446L11.1569 5.38336C10.24 3.63384 9.3443 1.92439 9.15247 1.53677C8.78402 0.792259 8.04547 0.0898438 7.00007 0.0898438ZM6.19193 2.20209C6.59608 1.38576 7.4041 1.38576 7.80809 2.20209C8.01019 2.61046 8.9201 4.3467 9.82966 6.08227C10.7383 7.81606 11.6466 9.54924 11.8485 9.95719C12.2525 10.7735 11.8485 11.5898 10.6364 11.5898L3.3636 11.5898C2.15153 11.5898 1.74749 10.7735 2.15153 9.95719C2.35379 9.54856 3.26471 7.81033 4.17483 6.07364C5.08286 4.34095 5.99008 2.60978 6.19193 2.20209Z"
                        fill="#FED571" />
                    </svg>
                  </div>
                </template>
                <template v-else>
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="28" height="28" rx="8" fill="#91D0FF" />
                    <path
                      d="M15 10.5C15 11.0523 14.5523 11.5 14 11.5C13.4477 11.5 13 11.0523 13 10.5C13 9.94772 13.4477 9.5 14 9.5C14.5523 9.5 15 9.94772 15 10.5Z"
                      fill="#002133" />
                    <path
                      d="M14.75 13.25C14.75 12.8358 14.4142 12.5 14 12.5C13.5858 12.5 13.25 12.8358 13.25 13.25V17.75C13.25 18.1642 13.5858 18.5 14 18.5C14.4142 18.5 14.75 18.1642 14.75 17.75V13.25Z"
                      fill="#002133" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14 21C17.866 21 21 17.866 21 14C21 10.134 17.866 7 14 7C10.134 7 7 10.134 7 14C7 17.866 10.134 21 14 21ZM14 19.5C17.0376 19.5 19.5 17.0376 19.5 14C19.5 10.9624 17.0376 8.5 14 8.5C10.9624 8.5 8.5 10.9624 8.5 14C8.5 17.0376 10.9624 19.5 14 19.5Z"
                      fill="#002133" />
                  </svg>
                </template>
              </span>
              <div class="font-regular text-text-dark-500 text-[12px] leading-[20px]">
                <div class="text-light-200 font-regular text-[12px] leading-5">
                  <slot name="tips-content"></slot>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isShowIllustration" class="border-dark-200 rounded-[12px] border">
            <slot name="illustration"></slot>
          </div>
        </div>
        <div
          class="p-16"
          :class="{
            'border-dark-400 border-t border-solid': isShowIllustration || isShowTips,
          }">
          <GButtonV2
            v-if="!hideViewliveButton"
            data-test="editor-btn-after-publish"
            button-width="full"
            :loading="isLoading"
            @click="handleViewLive">
            {{ labelViewLive }}
          </GButtonV2>
        </div>
      </div>
    </template>
  </g-popover>
</template>
