<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.74149 3.37451C10.0867 3.37451 10.3665 3.65433 10.3665 3.99951V4.74175C11.8781 4.88123 12.5699 5.69678 12.8289 6.15517C12.9986 6.45572 12.8926 6.83698 12.592 7.00674C12.2915 7.1765 11.9102 7.07048 11.7405 6.76993C11.6425 6.59654 11.222 5.97144 9.90847 5.97119C8.71464 5.97096 8.0966 6.72006 8.0966 7.45845C8.0966 7.91789 8.28512 8.19412 8.64631 8.44117C9.01207 8.69134 9.494 8.86973 10.0848 9.08843L10.0849 9.08846L10.0857 9.08876C10.1621 9.11701 10.2402 9.14593 10.3201 9.17577C10.971 9.41872 11.7319 9.72119 12.3219 10.239C12.9486 10.7889 13.3565 11.5555 13.3565 12.6368C13.3565 13.5435 12.8997 14.2304 12.2648 14.6746C11.7193 15.0562 11.0404 15.2648 10.3665 15.3285V16.0001C10.3665 16.3453 10.0867 16.6251 9.74149 16.6251C9.39631 16.6251 9.11649 16.3453 9.11649 16.0001V15.2825C8.68516 15.2081 8.26826 15.0708 7.90126 14.8643C7.24588 14.4954 6.71766 13.8798 6.64573 13.0134C6.61717 12.6694 6.87289 12.3674 7.21688 12.3389C7.56088 12.3103 7.86289 12.566 7.89145 12.91C7.92103 13.2663 8.12643 13.5566 8.51438 13.775C8.91436 14.0001 9.47071 14.1196 10.0521 14.0971C10.6338 14.0746 11.1731 13.9128 11.5482 13.6504C11.9059 13.4002 12.1065 13.0705 12.1065 12.6368C12.1065 11.9197 11.857 11.494 11.4974 11.1785C11.1012 10.8308 10.5471 10.5947 9.88301 10.3468C9.80854 10.3191 9.73206 10.2909 9.65412 10.2623L9.654 10.2622C9.08752 10.0539 8.44434 9.81745 7.94062 9.47292C7.31558 9.0454 6.8466 8.41562 6.8466 7.45845C6.8466 6.16206 7.76538 5.10447 9.11649 4.80576V3.99951C9.11649 3.65433 9.39631 3.37451 9.74149 3.37451Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'PriceRangeCondition'
};
</script>
