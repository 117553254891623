<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path d="M11.2652 2.95338C10.5016 2.43416 9.49836 2.43416 8.7348 2.95338L4.64134 5.73694C3.61459 6.43512 3 7.59627 3 8.83791V16.25C3 16.6642 3.33579 17 3.75 17C4.16421 17 4.5 16.6642 4.5 16.25V8.83791C4.5 8.09293 4.86875 7.39624 5.4848 6.97733L9.57827 4.19377C9.83279 4.0207 10.1672 4.0207 10.4217 4.19377L14.5152 6.97733C15.1312 7.39624 15.5 8.09292 15.5 8.83791V16.25C15.5 16.6642 15.8358 17 16.25 17C16.6642 17 17 16.6642 17 16.25V8.83791C17 7.59627 16.3854 6.43512 15.3587 5.73694L11.2652 2.95338Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 16.9685C9.92009 16.9891 9.83632 17 9.75 17H6.75C6.19772 17 5.75 16.5523 5.75 16L5.75 12.5C5.75 11.9477 6.19772 11.5 6.75 11.5H7.5V8.5C7.5 7.94771 7.94772 7.5 8.5 7.5H11.5C12.0523 7.5 12.5 7.94771 12.5 8.5V11.5H13.25C13.8023 11.5 14.25 11.9477 14.25 12.5V16C14.25 16.5523 13.8023 17 13.25 17H10.25C10.1637 17 10.0799 16.9891 10 16.9685ZM10.75 13V15.5H12.75V13H10.75ZM9.25 15.5L9.25 13H7.25V15.5H9.25ZM11 11.5L11 9H9V11.5H11Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'InventoryCondition'
};
</script>
