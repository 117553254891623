<template>
  <svg width="100%" height="100%" viewBox="0 0 94 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.5" width="22" height="10" rx="3" fill="#AAAAAA" />
    <rect opacity="0.5" x="26" width="22" height="10" rx="3" fill="#AAAAAA" />
    <rect opacity="0.5" y="14" width="94" height="26" rx="3" fill="#5E5E5E" />
  </svg>
</template>

<script>
export default {
  name: 'TabTop',
};
</script>
