<template>
  <svg width="100%" height="100%" viewBox="0 0 60 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10" cy="10" r="10" fill="#D9D9D9"/>
<circle cx="34" cy="10" r="10" fill="#4A4A4A"/>
</svg>
</template>

<script>
export default {
  name: 'VariantPresetStyleColorSelected'
};
</script>
