<template>
  <svg width="100%" height="100%" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 3C0 1.34315 1.34315 0 3 0L47 0C48.6569 0 50 1.34315 50 3L50 33C50 34.6569 48.6569 36 47 36L3 36C1.34315 36 0 34.6569 0 33L0 3Z" fill="#5E5E5E"/>
<path d="M16 11.7188L21.994 9.63411C21.994 9.63411 22.7228 11.3341 25.006 11.3341C27.2891 11.3341 28.01 9.59961 28.01 9.59961L34 11.7188L33.003 16.9463L29.997 16.9463L29.997 26.3996L19.99 26.3996L19.99 16.9431H16.999L16 11.7188Z" fill="#F9F9F9"/>
<rect y="38" width="15.3333" height="12" rx="3" fill="#5E5E5E"/>
<rect x="17.334" y="38" width="15.3333" height="12" rx="3" fill="#5E5E5E"/>
<rect x="34.666" y="38" width="15.3333" height="12" rx="3" fill="#5E5E5E"/>
</svg>
</template>

<script>
export default {
  name: 'ProductImageLayoutBottom'
};
</script>
