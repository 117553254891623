<template>
  <svg width="100%" height="100%" viewBox="0 0 96 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28 6C28 2.68629 30.6863 0 34 0H62C65.3137 0 68 2.68629 68 6V30C68 33.3137 65.3137 36 62 36H34C30.6863 36 28 33.3137 28 30V6Z" fill="#5E5E5E"/>
<path d="M40.5 12.266L45.495 10.5288C45.495 10.5288 46.1024 11.9454 48.005 11.9454C49.9076 11.9454 50.5083 10.5 50.5083 10.5L55.5 12.266L54.6692 16.6222H52.1642V24.5H43.825V16.6196H41.3325L40.5 12.266Z" fill="#D6D6D6"/>
<rect x="31" y="40" width="34" height="6" rx="3" fill="#5E5E5E"/>
<rect x="36" y="50" width="24" height="6" rx="3" fill="#5E5E5E"/>
</svg>
</template>

<script>
export default {
  name: 'ProductLayoutVertical'
};
</script>
